/*
//=============//
//             //
//   RADIUS    //
//             //
//=============//
*/
#news_container_index .issue_content_news_item .inner,
#news_container_index .issue_content_news_item .inner .img_big_div
{
	-webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

#news_container_index .issue_content_news_item .inner .img_big_div img,
#news_container_gallery .img_big_div img
{
	width: 100%;
}
#news_container img.link_image,
#gallery_countries img.link_image
{
	width: 170px;
}

#news_container_index .issue_content_news_item .inner .img_big_div img,
#news_container_gallery .img_big_div img,
#news_container .link_title,
{
	border-radius: 10px 10px 0 0;
}
#news_container_index .issue_content_news_item .inner .issue_content_news_item_subtitle,
#news_container_gallery .issue_content_news_item .inner .issue_content_news_item_subtitle,
#news_container img.link_image,
#gallery_countries img.link_image,
#news_container .link_footer,
#news_container_gallery .img_big_div
{
	border-radius: 0 0 10px 10px;
}
#index_sitemap_block .sitemap_body,
#news_container .info_block,
#index_info_block .info_body,
#blog_sitemap_block .sitemap_body
{
	border-radius: 10px;
}
.sitemap_caption,
.info_caption,
nav.navbar .ul .li .text,
#blog_search_block .s_text,
#blog_search_block .s_input,
.issue_content_blog_item .flag img,
.gallery_contents_item.very_small_pics img,
.gallery_contents_item.very_small_pics,
#news_container_gallery .flag_likes
{
	border-radius: 5px;
}
.index_banner_place {
	border: none;
}
.index_banner_place img,
/*#news_container_gallery .fancybox .img_big_div img,*/
.blog_accommodation_country
{
	border-radius: 10px;
}
.blog_accommodation_country .name {
	border-radius: 0 5px 5px 0;
}
.blog_accommodation_country .icon {
	border-radius: 5px 0 0 5px;
}
