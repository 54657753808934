#main_title .txt,
#main_title .img
{
	/*
	float: left;
	height: 60px;
	margin-left: 20px;
	*/
}
#main_title .img {
	width: 48px;
	/*
	margin-top: 12px;
	*/
}
#main_title .arrow_prev,
#main_title .arrow_next,
#main_title .list_all_countries
{
	width: 60px;
	/*
	height: 32px;
	height: 64px;
	*/
	font-size: 16px;
	font-weight: bold;
	float: right;
	/*
	border: 1px solid #abbfc1;
	*/
	text-align: left;
	line-height: 30px;
}
#main_title .arrow_prev {
	margin-right: 10px;
}
#main_title .arrow_prev img,
#main_title .arrow_next img
{
	/*
	margin-top: -2px;
	*/
}
#main_title .arrow_prev:hover,
#main_title .arrow_next:hover
{
	/*
	background-color: #465b6b;
	border: 1px solid #eee;
	color: #fff;
	*/
}
#main_title .arrow_prev {
	background: url('/images/arrow_prev_small.png') no-repeat right -1px;
	background: url('/images/arrows-left-icon.png') no-repeat right -1px;
	padding-right: 5px;
}
#main_title .arrow_next {
	background: url('/images/arrow_next_small.png') no-repeat left 0;
	background: url('/images/arrows-right-icon.png') no-repeat left 0;
	padding-left: 5px;
	text-align: right;
}
#main_title .list_all_countries {
	width: 32px;
	height: 32px;
	cursor: pointer;
}
#main_title .list_all_countries .list_countries_block {
	display: none;
	position: absolute;
	margin-left: -218px;
	margin-left: -148px;
	z-index: 88888;
	border: 1px solid #666;
	width: 250px;
	height: 446px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #e6f0fb;
	border: 1px solid #abbfc1;
}
#main_title .list_all_countries .list_countries_block .item {
	float: left;
	cursor: pointer;
	text-align: left;
	padding: 5px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #e6f0fb;
	/*
	height: 22px;
	*/
	width: 220px;
}
#main_title .list_all_countries .list_countries_block .item .text {
	font-family: 'OpenSansSemibold';
	font-size: 16px;
	margin-top: -5px;
}
#main_title .list_all_countries .list_countries_block .item:hover
{
	color: #fff;
	background-color: #465b6b;
}


#main_title .block_all_countries_yes {
	position: relative;
}
#main_title .block_all_countries_yes div {
	float: left;
}
#main_title .block_all_countries_yes .flag {
	width: 32px;
}
#main_title .block_all_countries_yes .prev .link,
#main_title .block_all_countries_yes .next .flag,
#main_title .block_all_countries_yes .prev,
#main_title .block_all_countries_yes .next,
#main_title .block_all_countries_yes .all
{
	margin: 0;
	padding: 0;
}
#main_title .block_all_countries_yes .prev {
	margin-right: 10px;
}
#main_title .block_all_countries_yes .next {
	margin-left: 10px;
}
#main_title .block_all_countries {
	margin: 0;
	padding: 0;
}
#main_title .list_all_countries .list_countries_block {
	margin-top: 34px;
}
#main_title .list_all_countries .list_countries_block .item {
	height: 26px;
	padding-top: 2px;
}

