#index_sitemap_block,
#index_info_block,
#map_data
{
	padding-left: 15px;
	padding-right: 15px;
}
#index_sitemap_block .sitemap_caption,
#index_info_block .info_caption
{
	padding: 5px;
	padding-left: 10px;
	text-align: left;
	background-color: $color_default;
	color: $color_white;
	font-weight: bold;
}
#index_sitemap_block .sitemap_body,
#index_info_block .info_body
{
	padding: 10px;
	border: 1px solid $color_grey;
	background-color: $color_eee;
}
#index_sitemap_block .sitemap_item1,
#index_info_block .info_item1
{
	padding-left: 0;
}
#index_sitemap_block .sitemap_item2,
#index_info_block .info_item2
{
	padding-left: 10px;
}
#news_container p,
#index_sitemap_block .passive_link,
#index_sitemap_block .active_link,
#index_sitemap_block .sitemap_item2,
#index_info_block .passive_link,
#index_info_block .active_link,
#index_info_block .info_item2
{
	font-size: 16px;
}
#index_sitemap_block .sitemap_caption,
#index_sitemap_block .sitemap_item1,
#index_info_block .info_caption,
#index_info_block .info_item1
{
	font-size: 18px;
}
#index_sitemap_block .passive_link,
#index_info_block .passive_link
{
	color: $color_999;
	font-style: italic;
}
#index_sitemap_block .active_link:hover,
#index_info_block .active_link:hover
{
	background-color: $color_white;
	color: $color_black;
}

#yandex_rtb_block,
#google_adsense_block
{
	padding-left: 9px;
	padding-right: 9px;
	opacity: .333;
}
#yandex_rtb_block:hover,
#google_adsense_block:hover
{
	opacity: 1;
}
