.h5, h5 {
	font-size: 32px;
}
.h6, h6 {
	font-size: 24px;
}
.vcenter {
	display: inline-block;
  vertical-align: middle;
  float: none;
  text-align: center;
}
section#tillypad_carousel {
	height: 80px;
}
section#tillypad_contents .title a,
section#tillypad_contents h1
{
	font-size: 30px;
	color: $color_default;
	font-family: 'OpenSans';
}
section#tillypad_contents .title a:hover {
	color: $color_orange;
}
section#tillypad_contents .subtitle,
section#tillypad_contents .subtitle_green,
section#tillypad_contents .title_green,
section#tillypad_contents h2
{
	font-family: "OpenSansLight";
  color: $color_black;
  font-size: 18px;
  line-height: 28px;
}
section#tillypad_contents .subtitle_green,
section#tillypad_contents .title_green
{
	color: $color_green;
}
section#tillypad_contents .subtitle_green.error {
	font-size: 14px;
}
section#tillypad_contents .title_green {
	font-size: 34px;
	line-height: 44px;
	padding-left: 9px;
}
section#tillypad_contents a.subtitle_green_small:hover,
section#tillypad_contents a.subtitle_green:hover,
section#tillypad_contents .main_text a:hover
{
	color: $color_orange;
}
section#tillypad_contents .subtitle h1,
section#tillypad_contents .subtitle h2
{
	font-family: "OpenSansLight";
	color: $color_black;
  font-size: 24px;
  line-height: 30px;
}
section#tillypad_contents .subtitle a h1,
section#tillypad_contents .subtitle a h2
{
	color: $color_green;
}
section#tillypad_contents .main_text,
section#tillypad_contents td,
section#tillypad_contents li,
section#tillypad_contents p,
section#tillypad_contents .more_link a,
section#tillypad_contents .subtitle_green_small,
section#tillypad_contents .main_text a,
section#tillypad_contents .news_next_link a,
section#tillypad_contents .news_prev_link a
{
	font-family: "OpenSans";
  color: $color_black;
  font-size: 14px;
  line-height: 24px;
}
section#tillypad_contents .news_next_link,
section#tillypad_contents .news_prev_link
{
	padding-left: 9px;
	padding-right: 9px;
}
section#tillypad_contents .news_next_link .in,
section#tillypad_contents .news_prev_link .in
{
	padding-left: 20px;
	padding-right: 20px;
	background: url("/images/arrow_next_small.png") no-repeat center right;
}
section#tillypad_contents .news_prev_link .in {
	background: url("/images/arrow_prev_small.png") no-repeat center left;
}
section#tillypad_contents .subtitle_green_small,
section#tillypad_contents .main_text a,
section#tillypad_contents .news_next_link a,
section#tillypad_contents .news_prev_link a
{
	font-family: "OpenSansLight";
  color: $color_green;
}
section#tillypad_contents .more_link a {
	font-family: "OpenSansLight";
  color: $color_green;
}
section#tillypad_contents .more_link a:hover {
  color: $color_orange;
}
section#tillypad_contents .title,
section#tillypad_contents .subtitle,
section#tillypad_contents .more_link,
section#tillypad_contents .main_text,
section#tillypad_contents .others
{
	padding-left: 9px;
	padding-right: 9px;
}
section#tillypad_contents .main_text_link a {
	font-size: 12px;
}
section#tillypad_contents .divider_wrap {
	padding-left: 9px;
  padding-right: 9px;
  height: 1px;
}
section#tillypad_contents .divider_wrap .divider {
	background-color: $color_grey;
	height: 1px;
  width: 100%;
}
section#tillypad_contents #news_loading {
	display: none;
}
section#tillypad_contents .main_text_block,
section#tillypad_contents .main_text_link
{
	padding: 0;
}
section#tillypad_contents .main_text .subtitle {
	padding: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}
section#tillypad_contents .row_h20 {
	height: 20px;
}
section#tillypad_contents .carousel-indicators li {
	-webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background-color: $color_white;
	border: 1px solid $color_green;
	width: 10px;
	height: 10px;
	cursor: pointer;
}
section#tillypad_contents .carousel-indicators li.active {
	border: 1px solid $color_white;
	border: 1px solid $color_green;
  background-color: $color_green;
}
section#tillypad_contents #news_posts .item {
	padding: 0;
}
section#tillypad_contents .image_title {
	font-size: 12px;
	color: $color_text;
	font-style: italic;
}


section#tillypad_contents .carousel-caption.outside {
  position: relative;
  margin-top: 20px;
  margin-top: 10px;
  height: 50px;
  height: auto;
  
  /*
  background-color: pink;

  left: auto;
  right: auto;
  float: none;
  margin: 20px auto;
  
  
  left: 50%;
  margin-left: -35%;
  width: 70%;
  
  */
}
section#tillypad_contents .carousel-caption.inside {
  /*
  position: absolute;
  
  right: auto;

  text-align: left;
  height: auto;
  vertical-align: center;

  
  float: none;
  
  top: 50%;
	transform: translateY(-50%);
	*/
	
}
section#tillypad_contents .carousel-caption.inside {
  bottom: 0;
  top: 0;
  height: 100%;
}
section#tillypad_contents .carousel-caption .carousel_text {
	display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
}
section#tillypad_contents .carousel-caption.outside .carousel_text {
  width: 70%;
  text-align: center;
  /*
  background-color: pink;
  */
}
section#tillypad_contents .carousel-caption.outside .carousel_text h5 {
	font-size: 12px;
	color: $color_text;
	font-style: italic;
}
section#tillypad_contents #issue_untranslated_panel {
	display: none;
}
section#tillypad_contents .issue_content_item {
	clear: both;
	width: 100%;
}
section#tillypad_contents #issues_icon_pdf_links1_hover {
	display: none;
}
section#tillypad_contents .big_title {
	font-size: 30px;
	color: $color_green;
}


#news_container_gallery,
#news_container_index
{
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
}
#news_container_gallery .issue_content_news_item,
#news_container_index .issue_content_news_item
{
	padding-left: 5px;
	padding-right: 5px;
}
#news_container_gallery .issue_content_news_item img,
#news_container_index .issue_content_news_item img
{
	
}
#news_container_gallery .issue_content_news_item_subtitle,
#news_container_index .issue_content_news_item_subtitle
{
	background-color: $color_333;
	color: $color_white;
	margin-bottom: 15px;
	font-size: 16px;
	padding: 5px;
}
#news_container_gallery .issue_content_news_item_blank_short {
	margin-bottom: 15px;
}
#news_container_index .issue_content_news_item .inactive {
	-webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;
	/*
	filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
	*/
	cursor: default;
	opacity: 0.5;
}
#news_container_index .issue_content_news_item .main_hotinfo_div1,
#news_container_gallery .issue_content_news_item .main_hotinfo_div1
{
	position: absolute;
	display: none;
	margin-top: -150px;
	width: 100%;
	/*
	opacity: 1;
	
	position: absolute;
	
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	*/
}
#news_container_gallery .issue_content_news_item .main_hotinfo_div1 {
	/*
	display: block;
	*/
}
#news_container_index .issue_content_news_item .img_hotinfo_div1 .text1,
#news_container_gallery .issue_content_news_item .img_hotinfo_div1 .text1
{
	font-family: 'OpenSansBold';
  border-radius: 8px;

  font-size: 16px;
  font-weight: bold;
  padding: 5px;

  background-color: #666;
  color: #fff;
  border: 2px #fff solid;
}
#news_container_gallery .issue_content_news_item .img_hotinfo_div1 .text1 {
	font-family: 'OpenSansSemibold';
	padding: 2px;
	font-size: 16px;
	line-height: 17px;
	background-color: #990000;
	color: #fff;
  border: 2px #fff solid;
  font-weight: normal;
}