@font-face {
  font-family: "HP Simplified Bd";
  src: url("/fonts/HPSimplified.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified Rg";
  src: url("/fonts/HPSimplified_Rg.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified BdIt";
  src: url("/fonts/HPSimplified_BdIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "HP Simplified It";
  src: url("/fonts/HPSimplified_It.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "HP Simplified Lt";
  src: url("/fonts/HPSimplified_Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified LtIt";
  src: url("/fonts/HPSimplified_LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "HP Simplified";
  src: url("/fonts/HPSimplified_Lt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}