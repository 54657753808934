/***********************************
#	!! @breakpoint_xs:   0;      !! #
#	!! @breakpoint_sm:   576px;  !! #
#	!! @breakpoint_md:   768px;  !! #
#	!! @breakpoint_lg:   992px;  !! #
#	!! @breakpoint_xl:   1126px; !! #
***********************************/

@media all and (max-width: $brkpnt_xl)
{
	section#tillypad_news .news {
		height: auto;
		padding: 10px;
	}
}
