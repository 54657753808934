section#tillypad_news .col {
	background-color: #ffffee;
	border: 1px solid #666;
}
section#tillypad_news .item,
section#tillypad_news .news
{
	border: 1px solid #fff;
	background-color: #fff;
	color: #666;
	
	height: 50px;
	height: auto;
}
section#tillypad_news .news {
	height: 156px;
	padding-left: 20px;
}
section#tillypad_news .news .text {
	height: 80px;
	overflow-y: hidden;
	line-height: 16px;
}
section#tillypad_news .news .date {
	height: 20px;
	line-height: 20px;
	font-size: 11px;
}
section#tillypad_news .news .link {
	height: 40px;
	line-height: 40px;
	font-size: 11px;
}
section#tillypad_news .news .link a:hover {
	color: $color_orange;
}
section#tillypad_news .news .title {
	height: 24px;
	overflow: hidden;
	font-size: 16px;
	font-family: "OpenSansSemibold";
}
section#tillypad_news .news .row:hover .title {
	color: $color_orange;
}


section#tillypad_slider,
section#tillypad_news
{
	padding: 0;
}
section#tillypad_news .item {
	display: inline-block;
	overflow: hidden;
}
section#tillypad_news .item .row {
	position: absolute;
	bottom: 0;
}
section#tillypad_news a {
	color: #465B6B;
}
section#tillypad_slider {
	background-color: #666;
	background-color: $color_green;
}
section#tillypad_slider a {
	text-decoration: none;
}
section#tillypad_slider .carousel-caption.outside {
  position: relative;
  margin-top: 20px;
  height: 150px;
  
  /*
  left: auto;
  right: auto;
  float: none;
  margin: 20px auto;
  
  
  left: 50%;
  margin-left: -35%;
  width: 70%;
  
  */
}
section#tillypad_slider .carousel-caption.inside {
  /*
  position: absolute;
  
  right: auto;

  text-align: left;
  height: auto;
  vertical-align: center;

  
  float: none;
  
  top: 50%;
	transform: translateY(-50%);
	*/
	
}
section#tillypad_slider .carousel-caption.inside {
  bottom: 0;
  top: 0;
  height: 100%;
}
section#tillypad_slider .carousel-caption .carousel_text {
	display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
}
section#tillypad_slider .carousel-caption.outside .carousel_text {
  width: 70%;
  text-align: center;
  /*
  background-color: pink;
  */
}
section#tillypad_news .links_title {
  margin-top: 123px;
  height: 33px;
  width: 100%;
}
section#tillypad_news .links_title .title {
	display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  font-size: 16px;
}
section#tillypad_news .links_title:hover .title {
  color: $color_orange;
}

#news_container a.not_active_link,
#news_container a.not_active_link img,
#news_container a.not_active_link div
{
	-webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;
	/*
	filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
	*/
	cursor: default;
}
#news_container a.not_active_link img {
	opacity: 0.5;
  /*
  filter: alpha("opacity=50");
  */
}
