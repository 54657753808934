*:focus, button:focus, textarea:focus, input:focus {
	outline: none;
	border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/*
.fancybox-wrap {
	z-index: 999999999999;
}
*/
.ui-select span {
	display: none;
}
html, body, div, span, table, tr, td, p, textarea, input {
	font-family: "OpenSans";
	font-size: 16px;
}

html {
	overflow-y: scroll;
	overflow: -moz-scrollbars-vertical;
	/*
	height: 100%;
	*/
}
body {
	-ms-touch-action: none;
}

:root {
	--blue: #fff;
	--primary: #fff;
}

html, body {
	overflow-x: hidden;
	font-size: 1em;
}
body {
	overflow-y: hidden;
}
div {
	/*
	border: 1px solid red;
	*/
}
/*
.row.no-gutters {
	width: 100%;
}
*/
section {
	
}
.div_0px,
.div_3px,
.div_5px,
.div_10px,
.div_15px,
.div_20px,
.div_30px,
.div_40px,
.div_50px
{
	width: 100%;
	clear: both;
}
.div_0px {
	height: 0;
}
.div_3px {
	height: 3px;
}
.div_5px {
	height: 5px;
}
.div_10px {
	height: 10px;
}
.div_15px {
	height: 15px;
}
.div_20px {
	height: 20px;
}
.div_30px {
	height: 30px;
}
.div_40px {
	height: 40px;
}
.div_50px {
	height: 50px;
}
a, a:hover {
	color: $color_default;
	text-decoration: none;
}
p {
	font-size: 13px;
}

/***********************

	OVERLAY BLOCK

***********************/
#overlay {
	background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
  display: none;
}

/*****************

   MAIN

*****************/
#mozolin_main {
	position: absolute;
	position: relative;
	left: 50%;
	margin-left: -$brkpnt_xl_half;
	width: $brkpnt_xl;
	border-left: 1px solid #abbfc1;
	border-right: 1px solid #abbfc1;
}
#mozolin_main .row.empty {
	height: 0px;
}


/*****************

   TOP

*****************/
section#mozolin_top {
	padding-top: 9px;
	padding-bottom: 9px;
	background-color: $color_white;
}
section#mozolin_top .lang_place {
	/*
	width: 80px;
	height: 30px;
	margin-top: 5px;
	*/
	float: right;
	margin-right: -10px;
}
section#mozolin_top .row {
	/*
	padding: 0;
	*/
	margin: 0;
}
section#mozolin_top .slogan {
	padding-top: 2px;
	padding-left: 5px;
}
section#mozolin_top .lang_outer, section#mozolin_top .lang_outer_active {
	height: 20px;
	float: right;
	margin-left: 9px;
	cursor: pointer;
	border: solid $color_green 1px;
}
section#mozolin_top .lang_outer {
	border: solid $color_white 1px;
}
section#mozolin_top .lang_outer .lang:hover {
	color: $color_orange;
}
section#mozolin_top .lang_outer_active .lang, section#mozolin_top .lang_outer .lang {
	height: 16px;
	margin-top: -3px;
	font-family: "OpenSansSemibold";
	font-size: 16px;
	color: $color_green;
	padding-left: 2px;
	padding-right: 2px;
	padding-top: 1px;
	padding-bottom: 1px;
}
.untrans_text {
	display: none;
}

/*****************

   NAVIGATION

*****************/
nav.navbar {
	color: $color_default;
	margin: 0;
  padding: 0;

	/* shadow */
	/*
	-moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
  filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
  */
}
.navbar-toggler {
	
}
nav .navbar-toggler-icon {
	border: none;
	width: 30px;
  /*
  height: 22px;
	*/
	background: url("/images/menu.png") 0 0 no-repeat;

  width: 60px;
	height: 60px;
	background: url('/images/mobile_menu2_button.png') no-repeat 0 0;
}
nav .logo_phone {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
nav .logo_phone .logo {
	width: 144px;
	height: 32px;
	/*
	background:url("/images/tillypad_logo_feb14.png");
  */
  position: absolute;
  cursor:pointer;
  /*
  margin-top: 7px;
  margin-left: 11px;
  */
  top: 0;
}
nav .logo_phone .phone {
	position: absolute;
	top: 0;
	right: 0;
	/*
	margin-right: 11px;
	margin-top: 7px;
	*/
}
nav .menu {
	cursor: pointer;
	margin-right: 10px;
}
nav .nav-link {
	padding: 0;
}

nav.navbar .dropdown-menu.show .submenu_rows {
	padding: 15px;
	padding-bottom: 10px;
}
nav.navbar .dropdown-menu.show .submenu_cols {
	/*
	padding-top: 5px;
	*/
	padding-bottom: 5px;
}

/*
nav.navbar .dropdown-menu.show .submenu_cols,
nav.navbar .dropdown-menu.show .submenu_cols:focus,
nav.navbar .dropdown-menu.show .submenu_cols:active,
nav.navbar .dropdown-menu.show .submenu_cols::after,
nav.navbar .dropdown-menu.show .submenu_cols::before,
nav.navbar .dropdown-menu.show .submenu_cols:hover
{
	background-color: pink;
}
*/

.dropdown-menu {
  background-color: #fff !important;

  /* shadow */
	/*
	-moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
  filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
  */
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus {
  background-color: #fff !important;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #fff;
}

.navbar-expand-md .navbar-nav .nav-link {
	padding-left: 9px;
	padding-right: 9px;
}
nav .underline {
	height: 2px;
}
nav .underline.inactive {
	background-color: $color_white;
}
nav .menu:hover .underline.inactive {
	background-color: $color_default;
}
nav .underline.active {
	background-color: $color_default;
}
nav.navbar .ul {
  position: relative;
  margin-top: 48px;
  height: auto;
  padding-left: 10px;
}
nav.navbar .ul > .li {
  position: static;
  z-index: 1010;
  padding: 0;
}
nav.navbar .ul .li .text {
	font-family: 'OpenSansSemibold';
	font-size: 16px;
	font-weight: normal;
	line-height: 30px;
	border: 1px solid #abbfc1;
	background-color: $color_container;

	padding-left: $left_right_padding;
	padding-right: $left_right_padding;
}
nav.navbar .ul .li .text:hover {
	border: 1px solid $color_eee;
	border: 1px solid $color_default;
  background-color: $color_default;
  color: $color_white;
}
nav.navbar .ul > .li .dropdown-menu {
  width: 100%;    
  margin-left: -16px;
  transform: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  /*
  width: $brkpnt_xl;
  */
}
nav.navbar .ul .li .dropdown-menu.show {
	margin-top: 2px;
	margin-top: 0;
}
nav.navbar .dropdown a {
	color: $color_default;
}
nav.navbar .dropdown-menu a {
	color: $color_green;
}


.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #357EBD;
  background-image: linear-gradient(to bottom, #428BCA 0, #357EBD 100%);
}


nav .media {
	cursor: pointer;
	color: $color_green;
}
nav .media:hover {
	color: $color_orange;
}
nav .media:hover img.default {
	display: none;
}
nav .media img.hover {
	display: none;
}
nav .media:hover img.hover {
	display: block;
}



/*****************

   FOOTER

*****************/
section#mozolin_bottom {
	padding: 0;
	background-color: $color_white;
	border-top: 1px solid #abbfc1;
}

#mozolin_footer .row {
	/*
	padding: 15px;
	*/
}
#mozolin_footer .column {
	height: 25px;
}
#mozolin_footer .icon_bar_img img {
	margin-left: 5px;
}

#disabled_body {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999999999999;
	background-color: #666;
	opacity: .35;
	filter:Alpha(Opacity=35);
	display: none;
}

::selection {
	background-color: #666;
	color: #fff;
}
::-moz-selection {
	background-color: #666;
	color: #fff;
}
::-webkit-selection {
	background-color: #666;
	color: #fff;
}

body, input {
	margin: 0;
	padding: 0;
	background-color: $color_eee;
	color: $color_default;
	/*
	height: 100%;
	*/
}

img {
	border:0;
}

a {
	color: #666;
	text-decoration: none
}
a:hover {
	color: #990000;
}

.main_page_block,
.main_title
{
	position: relative;
	width: 100%;
	text-align: center;
	background-color: $color_container;
	clear: both;
}
.main_page_block a,
.main_page_block a:hover
{
	text-decoration: underline;
	text-decoration: none;
}
.main_title {
	border-top: 1px solid $color_grey;
}
#main_footer_block {
	position: relative;
	/*
	bottom: 0;
	left: -1px;
	height: 60px;
	
	border-left: 1px solid #abbfc1;
  border-right: 1px solid #abbfc1;
	*/
	width: 100%;
	background-color: #fff;
  border-top: 1px solid #abbfc1;
  font-size: 16px;
}
#main_footer_block .version_info {
	display: none;
	border: 1px #abbfc1 solid;
	border: 1px #666 solid;
	background-color: #ffffee;
	background-color: $color_eee;
	padding: 5px;
	color: #666;
	position: relative;
	font-size: 10px;
	margin-left: 5px;
	font-family: "OpenSansSemiBold";
}
#main_footer_block .copyright {
	cursor: pointer;
}
#main_footer_block .copyright,
#main_footer_block .email
{
	font-size: 16px;
	float: left;
	margin-left: 10px;
	margin-top: 5px;
	text-align: left;
}
#main_footer_block .copyright1 {
	font-size: 16px;
	color: $color_default;
}
#main_footer_block .promo_block,
#main_footer_block .promo,
#main_footer_block .promo_right,
#main_footer_block .footer_empty
{
	font-size: 16px;
	/*
	float: left;
	margin-left: 10px;
	margin-top: 14px;
	*/
	padding-top: 9px;
	padding-bottom: 9px;
}
#main_footer_block .promo {
	padding-left: 3px;
	padding-right: 3px;
}
#main_footer_block .footer_empty {
	margin-left: 40px;
}
#main_footer_block .promo_right {
	float: right;
	margin-right: 10px;
}
#main_title {
	position: relative;
	/*
	margin-top: 15px;
	*/
}
#main_title h1 {
	font-size: 32px;
	color: $color_default;
	font-family: 'OpenSansSemibold';
	padding-left: $left_right_padding;
	padding-right: $left_right_padding;
}
#news_container {
	position: relative;
	/*
	margin-top: 15px;
	*/
}
#banner_booking_com,
#banner_booking_join_com,
#banner_airbnb_com,
#banner_skyscanner_ru,
#banner_avia_yandex_ru
{
	/*
	margin: 15px auto;
	*/
	width: 468px;
	height: 60px;
	overflow: hidden;
	text-align: center;
	border: 1px solid #abbfc1;
	border-radius: 10px;
	opacity: .333;
}
#banner_booking_com:hover,
#banner_booking_join_com:hover,
#banner_airbnb_com:hover,
#banner_skyscanner_ru:hover,
#banner_avia_yandex_ru:hover
{
	opacity: 1;
}
#banner_airbnb_com {
	display: none;
}
#html_cache_template_file_name {
	display: none;
}
.tooltip {
	opacity: 1;
}
/*
.fancybox-overlay {
	width: 100%;
}
*/
#main_page_go_to_the_top_row {
	padding: 20px;
}

#main_page_go_to_the_top_row .click {
	cursor: pointer;
	
	-webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid $color_white;
  height: 40px;
  padding: 2px;

  width: 200px;
  background-color: $color_default;
}
#main_page_go_to_the_top {
	width: 32px;
	height: 32px;
	background: $color_white url('/images/top_16x16.png') no-repeat 8px 8px;
	cursor: pointer;

	padding: 5px;
  /*
  border: 1px solid $color_999;
  */
  
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
#main_page_go_to_the_top_row .text {
	padding-left: 8px;
	/*
	padding-left: 42px;
	padding-right: 8px;
	*/
}
#main_page_go_to_the_top_row .text div {
	font-size: 18px;
	color: $color_white;
}

.main_title {
	margin: 0 auto;
	padding: 10px;
	justify-content: center;
}
.main_title .main_title_icon,
.main_title .main_title_text
{
  float: left;
  padding: 0;
}
.main_title .main_title_text {
  padding-left: 10px;
}
p {
	font-size: 16px;
}
