/*
html * {
  font-family: "HP Simplified";
}
*/

/**************************

   App.vue

***************************/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	font-size: 16px;
}

#nav {
  padding: 30px;
}

html, body {
  background-color: #ddd;
  color: #333;
}

#loading {
	margin: 0 auto;
	width: 133px;
	background: #fff url('/images/loading_16.gif') 5px 6px;
	background-repeat: no-repeat;
	color: #333;
	font-weight: bold;
	text-align: left;
	padding: 5px;
	padding-left: 30px;
}

/**************************

   /pages

***************************/
th {
  text-align: right;
}
td {
  text-align: left;
}
/*
img {
  width: 200px;
  height: 133px;
  padding: 5px;
}
*/

/**************************

   LocaleSwitcher.vue

***************************/
span.lang {
  font-weight: bold;
  cursor: pointer;
}
span.lang.active {
  color: #42b983;
}

/**************************

   /components/custom

***************************/
.title {
  align-content: center;
  text-align: center;
}
.table {
  margin: 0 auto;
}
.table td {
  border: 1px solid #000;
  padding: 5px;
}
.table td {
  text-align: left;
}
.flag_small {
  width: 48px;
  height: 48px;
  padding: 5px;
}
.flag_small_no_padding {
	width: 32px;
  height: 32px;
	padding: 0;
}
.flag_default {
  width: 200px;
  padding: 5px;
}
.flag_big {
  width: 170px;
  height: 114px;
  border-radius: 15px;
  border: 1px solid #666;
  padding: 0;
  margin: 5px;
}



/**************************

   /home/CountriesList.vue

***************************/
.data {
  border: 1px solid #999;
  width: 75%;
  margin: 0 auto;
  padding: 10px;
  background-color: #eee;
  border-radius: 10px;
  width: 100%;
}

.data_title {
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  width: 99%;
  color: #fff;
  background-color: #666;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
}
/*
span {
  font-weight: bold;
}
*/
span.span_italic {
  font-style: italic;
  color: #999;
}


/**************************

   /gallery/CountriesPics.vue

***************************/
.pics_title {
  position: absolute;
  margin-left: -200px;
  margin-top: 10px;
  color: #fff;
  background-color: #333;
  text-decoration: none;
  font-style: normal;
  padding: 5px;
}


/**************************

   Map.vue

***************************/
tr.bg_dark {
	background-color: #eee;
}
tr.bg_light {
	background-color: #fff;
}

