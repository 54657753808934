#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
a {
  font-size: 16px;
  color: #666;
  text-decoration: none;
  font-style: normal;
}

#news_container a,
#config a
{
	color: $color_link;
}
#news_container a:hover,
#config a:hover
{
	text-decoration: none;
	color: $color_darkred;
}


/*
a:hover {
  background-color: #fff;
}
*/
a.router-link,
a.router-link:hover
{
	text-decoration: none;
	color: inherit;
}

/*
  list of countries: "/", "/gallery", "/blog"
*/
#index_sitemap_block a.router-link,
#index_sitemap_block span.blog_span_link
{
	cursor: pointer;
	text-decoration: none;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 16px;
}
#index_sitemap_block a.router-link:hover,
#index_sitemap_block span.blog_span_link:hover
{
	background-color: $color_default;
	color: $color_white;
}

#blogs_next_prev a.router-link,
#blogs_next_prev a.router-link:hover,
#blog-article a.router-link.image_only,
#blog-article a.router-link.image_only:hover
{
	background-color: transparent;
}

/*
  list of articles, download links, features, footer: "/blog", "/country/at", "/map"
*/
#map_data span.show_distances,
#map_data span.show_countries_list,
#mozolin_footer a,
#news_container .city_info_name,
#index_sitemap_block a.router-link.blog_list,
#country-articles a.router-link.blog_list,
#country-articles .more_link,
#info_downloads_list_block .gps_data_download .link a.blog_list,
.info_cities_list p a,
.info_cities_list a,
.modal-internal p a
{
	cursor: pointer;
	text-decoration: none;
	background-color: $color_container;
	color: $color_link;
	font-size: 16px;
}
.modal-internal p a {
	background-color: $color_white;
}
#mozolin_footer a {
	background-color: $color_white;
}
#news_container .city_info_name,
#map_data span.show_distances,
#map_data span.show_countries_list,
{
	background-color: $color_eee;
}
#country-articles .more_link {
	font-weight: bold;
}
#map_data span.show_distances:hover,
#map_data span.show_countries_list:hover,
#mozolin_footer a:hover,
#news_container .city_info_name:hover,
#index_sitemap_block a.router-link.blog_list:hover,
#country-articles a.router-link.blog_list:hover,
#country-articles .more_link:hover,
#info_downloads_list_block .gps_data_download .link a.blog_list:hover,
.info_cities_list a:hover,
.modal-internal p a:hover
{
	text-decoration: underline;
	color: $color_darkred;
}

#all_countries_list,
.show_countries_list
{
	display: none;
	width: 100%;
}
#all_countries_list {
	position: absolute;
	z-index: 9999;
	width: auto;
	height: 600px;
	overflow-y: auto;
	text-align: center;
	border: 1px #999 solid;
	background-color: #fff;
	margin-left: 20px;
}
