
.info_fancybox {
	position: absolute;
	top: 70px;
}

#news_container .link_icons {
	margin: 0 auto;
	height: 32px;
}
#news_container .link_dates,
#news_container .link_places
{
	clear: both;
}
#news_container .link_places .link_places_info,
#news_container .link_places .link_places_info_empty,
#news_container .link_places .link_places_title
{
	float: left;
}
#news_container .link_places .link_places_info,
#news_container .link_places .link_places_info_empty
{
	width: 34px;
	height: 34px;
	cursor: help;
}
#news_container .link_places .link_places_info_empty {
	cursor: default;
}
#news_container .link_places .link_places_title {
	/*
	width: 414px;
	width: 100%;
	*/
	padding-top: 4px;
	padding-left: 4px;
}
.link_places_html .contents {
	font-size: 14px;
	/*
	margin-right: 30px;
	*/
	padding: 10px;
	padding-right: 36px;
}
.link_places_html .contents b {
	font-size: 16px;
}
.link_places_html a {
	color: #990000;
	font-weight: bold;
}
.link_places_html a:hover {
	text-decoration: underline;
}
#news_container .link_footer a:hover {
	background-color: transparent;
}
#news_container .link_icon,
#news_container .link_icon_info
{
  /*
  float: left;
  */
  cursor: pointer;
  padding: 7px;
  margin: 2px;
  background-color: $color_white;
  border: 1px solid $color_999;
  
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
#news_container .link_icon.lnk_icons_w3,
#news_container .link_icon_info.lnk_icons_w3,
#news_container .link_icon_article.lnk_icons_w3
{
  width: 32px;
}
#news_container .link_icon_info {
	cursor: pointer;
	cursor: help;
}
#news_container .link_icon.lnk_icons_w3,
#news_container .link_icon_info.lnk_icons_w3,
#news_container .link_icon_article.lnk_icons_w3
{
  width: 32px;
}
#news_container .link_icon.lnk_icons_w2,
#news_container .link_icon_info.lnk_icons_w2
{
  width: 48px;
  text-align: right;
}
#news_container .link_icon_info.lnk_icons_w2
{
  text-align: left;
}
#news_container .link_icon.lnk_icons_w1,
#news_container .link_icon_info.lnk_icons_w1,
#news_container .link_icon_article.lnk_icons_w1
{
  width: 96px;
}
#news_container .link_title,
#news_container .link_footer
{
	/*
	font-family: 'OpenSansSemibold';
	*/
	margin: 0;
	text-align: center;
	font-size: 16px;
	background-color: $color_333;
	color: #fff;
	padding: 2px;
}
#news_container .link_footer {
	margin-top: -60px;
}
/*
.fancybox-overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 320px;
}
(*/
.info_cities_list .articles_body .hidden_related_items {
	display: none;
}
.info_cities_list .articles_body .more_related_items {
	display: block;
}
#country-articles {
	margin-top: 10px;
}
/*
.info_cities_list .articles_body .more_link {
	font-size: 16px;
	color: #0070c0;
	cursor: pointer;
	font-style: italic;
	padding-top: 0px;
	padding-left: 18px;
	font-weight: bold;
}
.info_cities_list .articles_body .more_link:hover {
	color: $color_darkred;
}
*/
#news_container .articles_body a,
.info_cities_list .articles_body a,
#news_container .modal-internal a
{
	font-size: 16px;
	color: #0070c0;
	text-decoration: none;
}
#news_container .articles_body a:hover,
.info_cities_list .articles_body a:hover,
#news_container .modal-internal a:hover
{
	color: #900;
}

#news_container .modal-internal {
	background-color: #fff;
	text-align: left;
	height: 100%;
	display: flex;
  justify-content: center;
  align-items: center;
}
#news_container .city_info_icon {
	width: 32px;
	background: url('/images/finger_info_32x32_03.png') no-repeat 0 0;
}
#news_container .city_info_accommodation
{
	clear: both;
  width: auto;
}
#news_container .city_info_icon,
#news_container .city_info_name
{
	height: 32px;
	float: left;
	cursor: pointer;
}
#news_container .city_info_name {
	padding-left: 5px;
	height: 100%;
	display: flex;
  justify-content: center;
  align-items: center;
}

#news_container .city_info_accommodation .vue-picture-swipe-feat {
	padding-top: 5px;
	clear: both;
}


/******************
*                 *
*   GOOGLE MAPS   *
*                 *
*******************/
.map {
  width: 100%;
  height: 400px;
}
.marker-list {
	max-height: 128px !important;
	overflow-y: auto;
}
.marker-list-item {
	color: #333;
	background-color: #eee;
	text-align: left;
	border: 0px solid #999;
	cursor: pointer;
	height: 32px;
	line-height: 32px;
}
.marker-list-item-icon {
	width: 38px;
}
.marker-list-item-title {
	padding-left: 5px;
}
.marker-list-item-icon,
.marker-list-item-title
{
	float:left;
	height: 100%;
	
}
.marker-list-item .marker-list-item-icon {
	background: #eee url("/images/city_32x32_01.png") no-repeat 2px 0;
}
.marker-list-item.kml  .marker-list-item-icon {
	background: #eee url("/images/marker_32x32_navigate.png") no-repeat 2px 0;
}
.marker-list-item:hover {
	color: #fff;
	background-color: #465b6b;
}
