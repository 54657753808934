.gallery_likes .blog_big_like_icon.flag_likes_blog,
.blog_likes .blog_big_like_icon.flag_likes_blog,
.blog_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog
{
	position: relative;
  width: auto;
	height: 38px;
	cursor: pointer;
	border: 1px solid #ccc;
	padding-left: 40px;
	padding-right: 6px;
	padding-top: 2px;
	background: #fff url("/images/heart_32x32_01.png") no-repeat 4px 1px;
	-webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
	color: #000;
	font-size: 20px;
	font-weight: normal;
	float: left;
}
.blog_all_likes .blog_big_like_icon.flag_likes_blog.blog {
	background: #fff url("/images/heart_32x32_03.png") no-repeat 4px 2px;
}

.blog_all_likes .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog
{
	cursor: default;	
}
.blog_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog
{
	background: #fff url("/images/finger_info_32x32_03.png") no-repeat 4px 0;
	background: #fff url("/images/text_32x32_01.png") no-repeat 4px 2px;
}
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog.gallery
{
	background: #fff url("/images/finger_gallery_32x32_03.png") no-repeat 4px 2px;
}
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog.files
{
	background: #fff url("/images/text_32x32.png") no-repeat 4px 2px;
}

.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog
{
	background: #fff url("/images/marker_32x32_navigate.png") no-repeat 4px 0;
	background: #fff url("/images/marker_32x32_azure") no-repeat 4px 0;
	background: #fff url("/images/castle_32x32_05.png") no-repeat 4px 2px;
	background: #fff url("/images/favicon.png") no-repeat 4px 2px;
}

.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog
{
	background: #fff url("/images/pushpin_32x32_azure.png") no-repeat 4px 0;
	background: #fff url("/images/marker_32x32_azure.png") no-repeat 4px 2px;
}

.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog
{
	background: #fff url("/images/flag_uk_32x32.png") no-repeat 4px 2px;
	background: #fff url("/images/city_32x32_04.png") no-repeat 4px 2px;
	background: #fff url("/images/city_32x32_01.png") no-repeat 4px 0;
}
.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog
{
	background: #fff url("/images/city_32x32_04.png") no-repeat 4px 0;
	background: #fff url("/images/city_32x32_01.png") no-repeat 4px 2px;
	background: #fff url("/images/flag_ru_32x32.png") no-repeat 4px 2px;
}


.blog_all_likes,
.blog_all_likes_zero,
.blog_all_likes_cc0,
.blog_all_likes_cc1,
.blog_all_likes_cc2,
.blog_all_likes_cc3
{
  color: #fff;
  font-weight: bold;
  text-align: center;
  clear: both;
  padding: 5px;
}
.gallery_likes,
.blog_likes,
.blog_likes_zero
{
  background-color: red;
  color: #fff;
  font-weight: bold;
  width: 32px;
  text-align: center;
}
.blog_likes_zero {
  background-color: black;
}
