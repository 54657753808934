
/* font-family: "OpenSansRegular"; */
@font-face {
    /*
    font-family: "OpenSansRegular";
    */
    font-family: 'OpenSans';
    font-display: swap;
    src: url("/fonts/opensans/OpenSansRegular/OpenSansRegular.eot");
    src: url("/fonts/opensans/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansRegular/OpenSansRegular.woff") format("woff"),
    url("/fonts/opensans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansLight"; */
@font-face {
    font-family: "OpenSansLight";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansLight/OpenSansLight.eot");
    src: url("/fonts/opensans/OpenSansLight/OpenSansLight.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansLight/OpenSansLight.woff") format("woff"),
    url("/fonts/opensans/OpenSansLight/OpenSansLight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansItalic"; */
@font-face {
    font-family: "OpenSansItalic";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansItalic/OpenSansItalic.eot");
    src: url("/fonts/opensans/OpenSansItalic/OpenSansItalic.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansItalic/OpenSansItalic.woff") format("woff"),
    url("/fonts/opensans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansBold"; */
@font-face {
    font-family: "OpenSansBold";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansBold/OpenSansBold.eot");
    src: url("/fonts/opensans/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansBold/OpenSansBold.woff") format("woff"),
    url("/fonts/opensans/OpenSansBold/OpenSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansExtraBold"; */
@font-face {
    font-family: "OpenSansExtraBold";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.eot");
    src: url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"),
    url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansExtraBoldItalic"; */
@font-face {
    font-family: "OpenSansExtraBoldItalic";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot");
    src: url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff") format("woff"),
    url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansBoldItalic"; */
@font-face {
    font-family: "OpenSansBoldItalic";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
    src: url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"),
    url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansRegularItalic"; */
@font-face {
    font-family: "OpenSansRegularItalic";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.eot");
    src: url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.woff") format("woff"),
    url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansSemiBold"; */
@font-face {
    font-family: "OpenSansSemiBold";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.eot");
    src: url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"),
    url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "OpenSansSemiBoldItalic"; */
@font-face {
    font-family: "OpenSansSemiBoldItalic";
    font-display: swap;
    src: url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot");
    src: url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff") format("woff"),
    url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
