.div_0px,
.div_3px,
.div_5px,
.div_10px,
.div_15px,
.div_20px,
.div_30px,
.div_40px,
.div_50px
{
	width: 100%;
	clear: both;
}
.div_0px {
	height: 0;
}
.div_3px {
	height: 3px;
}
.div_5px {
	height: 5px;
}
.div_10px {
	height: 10px;
}
.div_15px {
	height: 15px;
}
.div_20px {
	height: 20px;
}
.div_30px {
	height: 30px;
}
.div_40px {
	height: 40px;
}
.div_50px {
	height: 50px;
}

#main_menu_block {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background-color: #eee;
	z-index: 8000;
}
#main_menu_shadow_down {
	background: url("/images/shadow_down.png") repeat 0 0;
	height: 30px;
	width: $brkpnt_xl;
	position: fixed;
	top: 60px;
	left: 50%;
	margin-left: -600px;
	z-index: 8000;
}
#main_menu_inner_block {
	position: absolute;
	top: 0;
	width: $brkpnt_xl;
	height: 60px;
	left: 50%;
	margin-left: -600px;
	background-color: #fff;
	border-left: 1px solid #abbfc1;
	border-right: 1px solid #abbfc1;
	border-bottom: 1px solid #abbfc1;
}
#main_menu_block .main_menu_item,
#main_menu_block .main_menu_item .submenu_block,
#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block,
#main_menu_block .auth .submenu_block .item
{
	display: block;
	float: left;
	margin-left: 10px;
	margin-top: 12px;
	cursor: pointer;
	border: 1px solid #abbfc1;
	text-align: center;
	padding: 5px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: $color_container;
	/*
	height: 22px;
	*/
}
#main_menu_block .main_menu_item:hover,
#main_menu_block .main_menu_item.active,
#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover
{
	border: 1px solid #eee;
	background-color: #465b6b;
}
#main_menu_block .main_menu_item .text,
#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block .item
{
	font-family: 'OpenSansSemibold';
	font-size: 16px;
	font-weight: normal;
}
#main_menu_block .main_menu_item .text:hover,
#main_menu_inner_block a:hover,
#main_menu_block .main_menu_item.active .text,
#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover
{
	color: #fff;
}
#main_menu_mobile_button {
	display: none;
	z-index: 9999;
	position: absolute;
	top: 0;
	left: 0;
	width: 60px;
	height: 60px;
	background: url('/images/mobile_menu2_button.png') no-repeat 0 0;
	cursor: pointer;
}
#main_menu_mobile_button:hover {
	background: url('/images/mobile_menu_button_hover.png') no-repeat 0 0;
}
#main_menu_mobile_block {
	display: none;
	z-index: 9999;
	position: absolute;
	top: 61px;
	left: 0;
	width: 400px;
	background-color: #fff;
}
#main_menu_block .main_menu_mobile_item {
	border: 1px solid #abbfc1;
	background-color: #fff;
}
#main_menu_block .main_menu_mobile_item:hover,
#main_menu_block .main_menu_mobile_item.active
{
	/*
	border: 1px solid #eee;
	*/
	background-color: #465b6b;
}
#main_menu_block .main_menu_mobile_item .text {
	font-family: 'OpenSansSemibold';
	font-size: 24px;
	margin: 10px;
}
#main_menu_block .main_menu_mobile_item .text:hover,
#main_menu_block .main_menu_mobile_item.active .text
{
	color: #fff;
}


#main_menu_block .main_menu_item .submenu_block,
#main_menu_block .auth .submenu_block
{
	display: none;
	position: absolute;
	top: 33px;
	margin-left: -21px;
	height: auto;
	padding: 0;
}
#main_menu_block .auth .submenu_block .close_button {
	background-image: url('/images/fancybox_sprite.png');
	position: absolute;
	top: -18px;
	right: -18px;
	width: 36px;
	height: 36px;
	cursor: pointer;
	z-index: 8989;
}
#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block .item
{
	color: #666;
	border: none;
	float: none;
	margin: 0;
	text-align: left;
}
#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover
{
	border: none;
}

/******************************

   LANGUAGE & AUTH

******************************/
#main_menu_lang_block {
	float: right;
	/*
	width: 165px;
	margin-top: 5px;
	position: absolute;
	top: 0px;
	right: 10px;
	*/
}
#main_menu_lang_block .lang_ru,
#main_menu_lang_block .lang_en,
#main_menu_lang_block .auth
{
	width: 48px;
	height: 48px;
	background-image: url('/images/countries/flags/flag_RU.png');
	cursor: pointer;
	float: right;
	margin-right: 10px;
}
#main_menu_lang_block .lang_en {
	background-image: url('/images/countries/flags/flag_UK.png');
	/*
	margin-right: 50px;
	*/
}
#main_menu_lang_block .lang_ru,
#main_menu_lang_block .lang_en
{
	width: 30px;
	height: 30px;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	/*
	margin-top: 10px;
	*/
	border: 1px solid #abbfc1;
}
#main_menu_lang_block .auth,
#main_menu_lang_block .auth span
{
	background: none;
	cursor: default;
	font-weight: bold;
	width: 32px;
	font-size: 16px;
	text-align: right;
}
#main_menu_lang_block .auth a,
#main_menu_lang_block .auth span
{
	color: #666;
}
#main_menu_lang_block .auth:hover,
#main_menu_lang_block .auth a:hover,
#main_menu_lang_block .auth span:hover
{
	color: #fff;
	cursor: pointer;
}
#main_menu_block .auth {
	margin-top: 8px;
}
#main_menu_block .auth .submenu_block {
	top: 38px;
	right: 124px;
}
#main_menu_block .qrcode_builder {
	width: 30px;
	height: 30px;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	margin-top: 10px;
	border: 1px solid #abbfc1;
	border: 1px solid #333;
	background-image: url('/images/qrcode_32x32_01.png');
	cursor: pointer;
}
#main_menu_block .qrcode_builder .qrcode_contents {
	background-color: #fff;
	padding: 5px;
	border: 1px solid #333;
	display: none;
	position: absolute;
	margin-top: 33px;
	right: 165px;
}
#main_menu_block .qrcode_builder .close_button {
	background-image: url('/images/fancybox_sprite.png');
	position: absolute;
	top: -18px;
	right: -18px;
	width: 36px;
	height: 36px;
	cursor: pointer;
	z-index: 8989;
}
#main_menu_block .auth .menu_avatar,
.avatar_tooltip_html
{
	width: 32px;
	height: 32px;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	float: left;
	margin: 2px;
}
.avatar_tooltip {
	background-color: #eee;
  border: 2px solid #abbfc1;
  display: none;
}
.avatar_tooltip_html
{
	width: 128px;
	height: 128px;
	display: block;
}
.avatar_tooltip_name {
	color: #666;
	font-family: 'OpenSansSemibold';
	font-size: 16px;
	/*
	color: #666;
	font-family: 'OpenSansSemibold';
	font-size: 16px;
	padding: 4px;
	border: 1px solid #666;
	background-color: #eee;
	*/
}
