
.pswp__top-bar {
  text-align: right;
}
.pswp__caption .pswp__caption__center {
  text-align: center !important;
}
.pswp__button--rotate {
  background: url("/images/styles/rotate.png") 0 0 no-repeat;
  background-size: 62px 24px;
}
.pswp__button--rotate--left {
  background-position: 8px 10px;
}
.pswp__button--rotate--right {
  background-position: -26px 10px;
}
.my-gallery img {
  width: 170px;
  border-radius: 10px;
}
.my-gallery.small img {
  width: 88px;
  border-radius: 5px;
}
.gallery-thumbnail {
  display: inline;
  /*
  margin: 5px;
  */
}
.gallery-thumbnail img {
	padding: 5px;
}
.gallery-thumbnail-likes,
.gallery-thumbnail-likes-big
{
	position: absolute;
	margin-top: 8px;
	margin-left: -162px;
	background: url("/images/heart_16x16_01.png") 2px 1px no-repeat #fff;
	width: auto;
	background-color: #fff;
	color: #333;
	height: 19px;
	font-weight: bold;
	padding-right: 2px;
	padding-left: 2px;
	padding-top: 2px;
	/*
	border: 1px solid red;
	*/
	border-radius: 5px;
}
.gallery-thumbnail-likes div,
.gallery-thumbnail-likes-big div
{
	padding-left: 18px;
	margin-top: -5px;
}
.gallery-thumbnail-likes-big {
	margin: 0 auto;
	top: -24px;
	cursor: pointer;
}
.gallery-thumbnail-likes-big.zero {
  background: url("/images/heart_16x16_03.png") 2px 1px no-repeat #fff;
}
