#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

a {
  font-size: 16px;
  color: #666;
  text-decoration: none;
  font-style: normal;
}

#news_container a,
#config a {
  color: #2a6fbd;
}

#news_container a:hover,
#config a:hover {
  text-decoration: none;
  color: #900;
}

/*
a:hover {
  background-color: #fff;
}
*/
a.router-link,
a.router-link:hover {
  text-decoration: none;
  color: inherit;
}

/*
  list of countries: "/", "/gallery", "/blog"
*/
#index_sitemap_block a.router-link,
#index_sitemap_block span.blog_span_link {
  cursor: pointer;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

#index_sitemap_block a.router-link:hover,
#index_sitemap_block span.blog_span_link:hover {
  background-color: #465b6b;
  color: #fff;
}

#blogs_next_prev a.router-link,
#blogs_next_prev a.router-link:hover,
#blog-article a.router-link.image_only,
#blog-article a.router-link.image_only:hover {
  background-color: transparent;
}

/*
  list of articles, download links, features, footer: "/blog", "/country/at", "/map"
*/
#map_data span.show_distances,
#map_data span.show_countries_list,
#mozolin_footer a,
#news_container .city_info_name,
#index_sitemap_block a.router-link.blog_list,
#country-articles a.router-link.blog_list,
#country-articles .more_link,
#info_downloads_list_block .gps_data_download .link a.blog_list,
.info_cities_list p a,
.info_cities_list a,
.modal-internal p a {
  cursor: pointer;
  text-decoration: none;
  background-color: #cfd4d5;
  color: #2a6fbd;
  font-size: 16px;
}

.modal-internal p a {
  background-color: #fff;
}

#mozolin_footer a {
  background-color: #fff;
}

#news_container .city_info_name,
#map_data span.show_distances,
#map_data span.show_countries_list {
  background-color: #eee;
}

#country-articles .more_link {
  font-weight: bold;
}

#map_data span.show_distances:hover,
#map_data span.show_countries_list:hover,
#mozolin_footer a:hover,
#news_container .city_info_name:hover,
#index_sitemap_block a.router-link.blog_list:hover,
#country-articles a.router-link.blog_list:hover,
#country-articles .more_link:hover,
#info_downloads_list_block .gps_data_download .link a.blog_list:hover,
.info_cities_list a:hover,
.modal-internal p a:hover {
  text-decoration: underline;
  color: #900;
}

#all_countries_list,
.show_countries_list {
  display: none;
  width: 100%;
}

#all_countries_list {
  position: absolute;
  z-index: 9999;
  width: auto;
  height: 600px;
  overflow-y: auto;
  text-align: center;
  border: 1px #999 solid;
  background-color: #fff;
  margin-left: 20px;
}

/*
html * {
  font-family: "HP Simplified";
}
*/
/**************************

   App.vue

***************************/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
}

#nav {
  padding: 30px;
}

html, body {
  background-color: #ddd;
  color: #333;
}

#loading {
  margin: 0 auto;
  width: 133px;
  background: #fff url("/images/loading_16.gif") 5px 6px;
  background-repeat: no-repeat;
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  padding-left: 30px;
}

/**************************

   /pages

***************************/
th {
  text-align: right;
}

td {
  text-align: left;
}

/*
img {
  width: 200px;
  height: 133px;
  padding: 5px;
}
*/
/**************************

   LocaleSwitcher.vue

***************************/
span.lang {
  font-weight: bold;
  cursor: pointer;
}

span.lang.active {
  color: #42b983;
}

/**************************

   /components/custom

***************************/
.title {
  align-content: center;
  text-align: center;
}

.table {
  margin: 0 auto;
}

.table td {
  border: 1px solid #000;
  padding: 5px;
}

.table td {
  text-align: left;
}

.flag_small {
  width: 48px;
  height: 48px;
  padding: 5px;
}

.flag_small_no_padding {
  width: 32px;
  height: 32px;
  padding: 0;
}

.flag_default {
  width: 200px;
  padding: 5px;
}

.flag_big {
  width: 170px;
  height: 114px;
  border-radius: 15px;
  border: 1px solid #666;
  padding: 0;
  margin: 5px;
}

/**************************

   /home/CountriesList.vue

***************************/
.data {
  border: 1px solid #999;
  width: 75%;
  margin: 0 auto;
  padding: 10px;
  background-color: #eee;
  border-radius: 10px;
  width: 100%;
}

.data_title {
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  width: 99%;
  color: #fff;
  background-color: #666;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
}

/*
span {
  font-weight: bold;
}
*/
span.span_italic {
  font-style: italic;
  color: #999;
}

/**************************

   /gallery/CountriesPics.vue

***************************/
.pics_title {
  position: absolute;
  margin-left: -200px;
  margin-top: 10px;
  color: #fff;
  background-color: #333;
  text-decoration: none;
  font-style: normal;
  padding: 5px;
}

/**************************

   Map.vue

***************************/
tr.bg_dark {
  background-color: #eee;
}

tr.bg_light {
  background-color: #fff;
}

#news_container {
  position: absolute;
  /*
  position: relative;
  left: 300px;
  background-color: yellow;
  width: 100%;

  1126 - 200px - 2*5px (margin)
  float: left;
  width: 916px;
  */
  margin: 0 auto;
  min-height: 450px;
  padding: 10px;
}

#news_container .issue_content_news_item,
#news_container .issue_content_news_item.vis,
#news_container .issue_content_news_item.invis {
  margin: 5px;
  margin-left: 5px;
  width: 210px;
  height: 156px;
  /*
  background-color:pink;
  height:100px;
  */
  border: 1px solid #abbfc1;
  min-width: 1px;
  /*
  display: none;
  overflow: hidden;
  */
  background-color: #eee;
}

#news_container .issue_content_news_item .flag {
  cursor: pointer;
}

#news_container .issue_content_news_item .info,
.info_fancybox {
  /*
  display: none;

  position: fixed;
  top: 65px;
  width: 1000px;
  left: 50%;
  margin-left: -500px;
  */
  /*
  height: 240px;
  */
  width: 1200px;
  /*
  height:100%;
  */
  background-color: #ffffee;
  z-index: 1;
  border: 1px solid #abbfc1;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: left;
  padding: 5px;
  min-height: 550px;
}

#news_container .issue_content_news_item .info .close {
  width: 26px;
  height: 26px;
  background-image: url("/images/icon_plus.png");
  position: absolute;
  top: 3px;
  right: 4px;
  cursor: pointer;
}

#news_container .issue_content_news_item.invis {
  visibility: hidden;
}

#news_container .issue_content_news_item.vis {
  visibility: visible;
}

#news_container .issue_content_news_item .tag {
  display: none;
}

#news_container .issue_content_news_item .inner {
  margin: 5px;
}

.pswp__top-bar {
  text-align: right;
}

.pswp__caption .pswp__caption__center {
  text-align: center !important;
}

.pswp__button--rotate {
  background: url("/images/styles/rotate.png") 0 0 no-repeat;
  background-size: 62px 24px;
}

.pswp__button--rotate--left {
  background-position: 8px 10px;
}

.pswp__button--rotate--right {
  background-position: -26px 10px;
}

.my-gallery img {
  width: 170px;
  border-radius: 10px;
}

.my-gallery.small img {
  width: 88px;
  border-radius: 5px;
}

.gallery-thumbnail {
  display: inline;
  /*
  margin: 5px;
  */
}

.gallery-thumbnail img {
  padding: 5px;
}

.gallery-thumbnail-likes,
.gallery-thumbnail-likes-big {
  position: absolute;
  margin-top: 8px;
  margin-left: -162px;
  background: url("/images/heart_16x16_01.png") 2px 1px no-repeat #fff;
  width: auto;
  background-color: #fff;
  color: #333;
  height: 19px;
  font-weight: bold;
  padding-right: 2px;
  padding-left: 2px;
  padding-top: 2px;
  /*
  border: 1px solid red;
  */
  border-radius: 5px;
}

.gallery-thumbnail-likes div,
.gallery-thumbnail-likes-big div {
  padding-left: 18px;
  margin-top: -5px;
}

.gallery-thumbnail-likes-big {
  margin: 0 auto;
  top: -24px;
  cursor: pointer;
}

.gallery-thumbnail-likes-big.zero {
  background: url("/images/heart_16x16_03.png") 2px 1px no-repeat #fff;
}

/***************
 *             *
 *   CAPTION   *
 *             *
 ***************/
.pswp__custom-caption {
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  color: #ffe;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  text-align: center;
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}

/***********************
 *                     *
 *   DOWNLOAD BUTTON   *
 *                     *
 ***********************/
button.pswp__button--test-button {
  background: #136912 !important;
  font-size: 20px;
  color: #fff;
}

/**********************************
 *                                *
 *   PRELOAD                      *
 *   debug: permanently display   *
 *                                *
 **********************************/
/*
.pswp-with-perma-preloader .pswp__icn {
  opacity: 0.85 !important;
}
*/
/********************
 *                  *
 *   LIKES BUTTON   *
 *                  *
 ********************/
.pswp__button--likes-button .inner {
  background-color: transparent;
  width: 50px;
  height: 60px;
  padding-top: 10px;
}

.pswp__button--likes-button .inner .like {
  position: absolute;
  background-color: #fff;
  text-align: center;
  width: 100%;
  height: 47px;
  padding: 0;
  border: 1px solid #666;
  border-radius: 10px;
}

.pswp__button--likes-button .inner .like img {
  border: 1px solid #fff;
}

.pswp__button--likes-button .inner .like div {
  padding: 0;
}

.pswp__button--likes-button .inner .like .likes-number {
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
}

.ui-tooltip, .arrow:after {
  background: #565656;
  border: 1px solid #fff !important;
}

.ui-tooltip {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Helvetica Neue", Sans-Serif;
  font-weight: normal;
  /*
  text-transform: uppercase;
  */
  color: #fff;
  box-shadow: 0 0 7px #565656;
  text-align: center;
}

.arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px;
}

.arrow.top {
  top: -16px;
  bottom: auto;
}

.arrow.left {
  left: 20%;
}

.arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 6px 5px 9px -9px #565656;
}

.arrow.top:after {
  bottom: -20px;
  top: auto;
}

.gallery_likes .blog_big_like_icon.flag_likes_blog,
.blog_likes .blog_big_like_icon.flag_likes_blog,
.blog_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog {
  position: relative;
  width: auto;
  height: 38px;
  cursor: pointer;
  border: 1px solid #ccc;
  padding-left: 40px;
  padding-right: 6px;
  padding-top: 2px;
  background: #fff url("/images/heart_32x32_01.png") no-repeat 4px 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #000;
  font-size: 20px;
  font-weight: normal;
  float: left;
}

.blog_all_likes .blog_big_like_icon.flag_likes_blog.blog {
  background: #fff url("/images/heart_32x32_03.png") no-repeat 4px 2px;
}

.blog_all_likes .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog {
  cursor: default;
}

.blog_likes_zero .blog_big_like_icon.flag_likes_blog,
.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog {
  background: #fff url("/images/finger_info_32x32_03.png") no-repeat 4px 0;
  background: #fff url("/images/text_32x32_01.png") no-repeat 4px 2px;
}

.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog.gallery {
  background: #fff url("/images/finger_gallery_32x32_03.png") no-repeat 4px 2px;
}

.blog_all_likes_zero .blog_big_like_icon.flag_likes_blog.files {
  background: #fff url("/images/text_32x32.png") no-repeat 4px 2px;
}

.blog_all_likes_cc0 .blog_big_like_icon.flag_likes_blog {
  background: #fff url("/images/marker_32x32_navigate.png") no-repeat 4px 0;
  background: #fff url("/images/marker_32x32_azure") no-repeat 4px 0;
  background: #fff url("/images/castle_32x32_05.png") no-repeat 4px 2px;
  background: #fff url("/images/favicon.png") no-repeat 4px 2px;
}

.blog_all_likes_cc1 .blog_big_like_icon.flag_likes_blog {
  background: #fff url("/images/pushpin_32x32_azure.png") no-repeat 4px 0;
  background: #fff url("/images/marker_32x32_azure.png") no-repeat 4px 2px;
}

.blog_all_likes_cc2 .blog_big_like_icon.flag_likes_blog {
  background: #fff url("/images/flag_uk_32x32.png") no-repeat 4px 2px;
  background: #fff url("/images/city_32x32_04.png") no-repeat 4px 2px;
  background: #fff url("/images/city_32x32_01.png") no-repeat 4px 0;
}

.blog_all_likes_cc3 .blog_big_like_icon.flag_likes_blog {
  background: #fff url("/images/city_32x32_04.png") no-repeat 4px 0;
  background: #fff url("/images/city_32x32_01.png") no-repeat 4px 2px;
  background: #fff url("/images/flag_ru_32x32.png") no-repeat 4px 2px;
}

.blog_all_likes,
.blog_all_likes_zero,
.blog_all_likes_cc0,
.blog_all_likes_cc1,
.blog_all_likes_cc2,
.blog_all_likes_cc3 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  clear: both;
  padding: 5px;
}

.gallery_likes,
.blog_likes,
.blog_likes_zero {
  background-color: red;
  color: #fff;
  font-weight: bold;
  width: 32px;
  text-align: center;
}

.blog_likes_zero {
  background-color: black;
}

.blog-world-main {
  width: 100%;
}

/*

.blog_big_like_icon.flag_likes_blog {

	position: relative;

  width: auto;

	height: 34px;

	cursor: pointer;

	border: 1px solid #ccc;

	padding-left: 36px;

	padding-right: 4px;

	background: #fff url("/images/heart_32x32_03.png") no-repeat 0 0;

	-webkit-border-radius: 5px;

  -moz-border-radius: 5px;

  border-radius: 5px;

	color: #000;

	font-size: 20px;

	font-weight: bold;

	float: left;

}

*/
#main_title .txt.articles {
  margin-top: 0px;
}

#main_title .txt.city_blog {
  margin-top: 0px;
}

h2 {
  margin-top: 60px;
  margin-top: 0;
  margin-bottom: 0;
}

.list_of_all_blocks,
.blog_accommodation_city {
  position: relative;
}

.list_of_all_blocks a,
.blog_accommodation_city a {
  /*

  text-decoration: underline;

  */
  color: #0070c0;
}

.list_of_all_blocks a:hover,
.blog_accommodation_city a:hover {
  color: #000099;
  color: #465b6b;
}

.blog_accommodation_city a.subtitle_green_small {
  /*

  text-decoration: underline;

   */
  color: #0070c0;
}

.blog_accommodation_city a.subtitle_green_small:hover {
  /*

  text-decoration: underline;

   */
  color: #900;
}

.blog_accommodation_country {
  border: 2px solid #fff;
  margin-bottom: 50px;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #eee;
}

.blog_accommodation_country .icon img {
  width: 32px;
  margin-left: 6px;
}

.blog_accommodation_country .name,
.blog_accommodation_country .icon {
  position: absolute;
  margin-top: -18px;
  margin-left: 10px;
  background-color: #fff;
  height: 32px;
  width: 40px;
}

.blog_accommodation_country .name {
  margin-top: -18px;
  margin-left: 50px;
  background-color: #cfd4d5;
  background-color: #fff;
  padding-left: 6px;
  padding-right: 6px;
  width: auto;
}

.blog_accommodation_country .name .inside {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
}

.blog_accommodation_city_upper {
  padding: 10px;
  background-color: #eee;
}

.blog_accommodation_city {
  padding: 10px;
  padding-top: 20px;
  background-color: #eee;
  /*

  background-color: #fff;

  */
}

.blog_accommodation_city .title {
  font-size: 20px;
  font-weight: bold;
}

.blog_accommodation_city p img {
  border: none;
}

.blog_accommodation_city {
  padding-left: 9px;
  padding-right: 9px;
}

.blog_ancor {
  /*

  position: absolute;

  margin-top: -40px;

  */
  margin-top: 0px;
  position: relative;
  height: 1px;
  width: 1px;
  color: #eee;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.blog_controls,
.blog_contents {
  position: relative;
  /*

  padding: 10px;

  */
}

.blog_accommodation_city_blog .blog_contents {
  text-align: center;
  margin: 0 auto;
}

.blog_accommodation_city_blog .blog_contents .test {
  width: auto;
}

.clear000,
.clear1000 {
  display: none;
}

.div_reload {
  height: 1px;
  display: none;
}

#blog_sitemap_block {
  padding-left: 9px;
  padding-right: 9px;
}

#blog_sitemap_block .sitemap_caption {
  padding: 5px;
  padding-left: 10px;
  text-align: left;
  background-color: #465b6b;
  color: #fff;
  font-weight: bold;
}

#blog_sitemap_block .sitemap_body {
  padding: 10px;
  border: 1px solid #abbfc1;
  background-color: #eee;
}

#blog_sitemap_block .sitemap_item1 {
  padding-left: 0;
}

#blog_sitemap_block .sitemap_item2 {
  padding-left: 10px;
}

.blog_accommodation_city p,
.blog_accommodation_city .test_row,
#blog_sitemap_block .sitemap_item2 {
  font-size: 16px;
}

.blog_accommodation_city h2,
.blog_accommodation_city h4 {
  color: #333;
  font-size: 30px;
}

.blog_accommodation_city h4 {
  font-size: 24px;
}

.blog_accommodation_city p {
  margin-bottom: 0;
  color: #333;
}

#blog_sitemap_block .sitemap_caption,
#blog_sitemap_block .sitemap_item1 {
  font-size: 18px;
}

.link_booking_map {
  cursor: pointer;
  min-height: 60px;
  background: #465b6b url("/images/map_48x48.png") no-repeat 6px 6px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
}

.link_booking_map .text {
  padding-left: 60px;
  padding-right: 8px;
  font-size: 18px;
  color: #fff;
}

.link_booking_map_html {
  /*

  background-color: $color_white;

  */
  border: 2px solid #fff;
  padding: 0;
}

.link_booking_map .text:hover {
  /*

  color: $color_darkred;

  */
}

.blog_accommodation_city .link_icon_title {
  font-size: 16px;
  padding-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  float: left;
}

.blog_accommodation_city a {
  text-decoration: none;
  color: #465b6b;
}

.blog_accommodation_city a:hover {
  color: #900;
}

.blog_accommodation_city .link_icon_info {
  cursor: pointer;
  padding: 0;
  margin: 2px;
  border: 1px solid #999;
  border: none;
  float: left;
  /*

  background-color: $color_white;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  -ms-border-radius: 100%;

  -o-border-radius: 100%;

  border-radius: 100%;

  */
}

#blog_sitemap_block hr {
  size: 1px;
  color: #999;
}

.list_of_all_blocks {
  margin: 0;
}

h4 {
  padding: 0;
  margin: 0;
}

.blog_accommodation_city .blog_related_articles .hidden_related_items {
  display: none;
}

.blog_accommodation_city .blog_related_articles .more_related_items {
  display: block;
}

.blog_accommodation_city .blog_related_articles .more_link {
  font-size: 16px;
  color: #0070c0;
  cursor: pointer;
  font-style: italic;
  padding-top: 0px;
  padding-left: 18px;
  font-weight: bold;
}

.blog_accommodation_city .blog_related_articles .more_link:hover {
  color: #900;
}

#blog_sitemap_block .sitemap_item1 a,
#blog_sitemap_block .sitemap_item2 a,
.blog_accommodation_city .blog_related_articles a {
  font-size: 16px;
  color: #0070c0;
  /*

  text-decoration: underline;

  */
}

#blog_sitemap_block a.active_link {
  color: #465b6b;
}

#blog_sitemap_block .sitemap_item1 a:hover,
#blog_sitemap_block .sitemap_item2 a:hover,
.blog_accommodation_city .blog_related_articles a:hover {
  color: #900;
}

#blog_sitemap_block a.active_link {
  font-size: 16px;
}

#blog_sitemap_block a.active_link:hover {
  background-color: #fff;
  color: #000;
}

#main_page_go_to_the_statistics_row {
  padding: 0;
}

#main_page_go_to_the_statistics_row .click {
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
  height: auto;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  background-color: #465b6b;
}

#main_page_go_to_the_statistics {
  width: 32px;
  height: 32px;
  background: #fff url("/images/bottom_16x16.png") no-repeat 8px 8px;
  cursor: pointer;
  padding: 5px;
  /*

  border: 1px solid $color_999;

  */
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

#main_page_go_to_the_statistics_row .text {
  padding-left: 8px;
  /*

  padding-left: 42px;

  padding-right: 8px;

  */
}

#main_page_go_to_the_statistics_row .text div {
  font-size: 18px;
  color: #fff;
}

p {
  font-size: 16px;
}

#blog-article a.router-link.image_only,
#blog-article a.router-link.image_only img {
  padding: 0 !important;
  margin: 0 !important;
}

#blog-article a.router-link.image_only img.flag_small {
  width: 38px;
  height: 38px;
  padding: 0px;
}

/**************************



   /country/CitiesPics.vue

   /gallery/CitiesPics.vue



***************************/
#news_container .link_title,
#gallery_countries .link_title {
  background-color: #000;
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  font-weight: bold;
  line-height: 19px;
  width: 170px;
}

#gallery_countries .link_title {
  border-radius: 10px 10px 0 0;
  text-decoration: none;
}

#gallery_countries.region .link_title {
  position: relative;
  width: 100%;
  height: 50px;
}

#gallery_countries.region .link_title .text_title {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cities-block {
  display: none;
}

.cities_info_blocks {
  height: 0;
}

.city-info {
  display: none;
  text-align: left;
  padding: 10px;
  text-decoration: none;
  font-style: normal;
  color: #333;
}

.city-info h2 {
  font-size: 20px;
  text-weight: bold;
  font-family: "HP Simplified Bd";
}

.block_item_downloads #info_block_downloads #news_container {
  /*
  position: relative;
  width: 100%;
  height: auto;
  */
}

.gallery_contents_item,
.gallery_contents_item.vis,
.gallery_contents_item.invis {
  margin: 5px;
  margin-left: 0;
  width: 260px;
  height: 198px;
  min-width: 1px;
  overflow: hidden;
  float: left;
}

.gallery_contents_item.very_small_pics {
  width: 88px;
  height: 58px;
  overflow: hidden;
}

.gallery_contents_item .flag {
  cursor: pointer;
}

.gallery_contents_item .info {
  display: none;
  position: absolute;
  top: 5px;
  width: 260px;
  background-color: #ffffee;
  z-index: 1;
  border: 1px solid #abbfc1;
  overflow: auto;
  text-align: left;
  padding: 5px;
}

.gallery_contents_item .info .close {
  width: 26px;
  height: 26px;
  background-image: url("/images/icon_plus.png");
  position: absolute;
  top: 3px;
  right: 4px;
  cursor: pointer;
}

.gallery_contents_item.invis {
  visibility: hidden;
}

.gallery_contents_item.vis {
  visibility: visible;
}

.gallery_contents_item .tag {
  display: none;
}

.gallery_contents_item .inner {
  /*
  margin: 5px;
  */
}

.info_cities_list {
  /*
  float: left;
  margin-right: 10px;

  margin-top: 10px;
  text-align: left;
  */
  width: 100%;
  text-align: left;
  padding: 0px;
  width: 100%;
}

.info_cities_list p {
  font-size: 16px;
}

.info_cities_list.big {
  /*
  width: 100%;
  */
}

.info_cities_list .change_size_icon,
.info_cities_list.big .change_size_icon {
  background-image: url("/images/resize_more_bw_32x32.png");
  width: 32px;
  height: 32px;
  position: absolute;
  margin-left: 384px;
  margin-top: 26px;
  cursor: pointer;
  z-index: 1;
}

.info_cities_list.big .change_size_icon {
  background-image: url("/images/resize_less_bw_32x32.png");
  margin-left: 804px;
}

#info_cities_info {
  text-align: left;
  padding: 10px;
  min-height: 0px;
  width: 100%;
}

#info_cities_info .close {
  display: none;
  width: 26px;
  height: 26px;
  background-image: url("/images/icon_plus.png");
  float: right;
  margin-top: -26px;
  margin-right: -23px;
  cursor: pointer;
}

#map_google, #big_pics_view {
  width: 450px;
  width: 100%;
  height: 450px;
}

#news_container .google {
  padding: 10px;
}

#big_pics_view {
  display: none;
  position: relative;
  top: 0px;
  overflow: hidden;
  border: none;
}

#big_pics_view_close {
  background-image: url("/images/fancybox_sprite.png");
}

#big_pics_view_close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8989;
  display: none;
}

#big_pics_view img {
  width: 450px;
  border: 1px solid #abbfc1;
  cursor: pointer;
}

#big_pics_view_overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: none;
  z-index: 8888;
  background: url("/images/fancybox_overlay_white.png");
}

/***********************************

   --- DOWNLOAD BLOCK ---

***********************************/
#info_downloads_list_block_empty_info {
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
}

#info_downloads_list_block .gps_data_download .gps_data {
  display: none;
}

#info_downloads_list_block .gps_data_download {
  display: none;
  /*
  height: 24px;
  */
  clear: both;
  padding-left: 10px;
}

#info_downloads_list_block .gps_data_download {
  padding: 1px;
  padding-left: 10px;
}

#info_downloads_list_block .gps_data_download .access,
#info_downloads_list_block .gps_data_download .empty {
  width: 26px;
  height: 26px;
  background: #fff url("/images/decline_16x16_03.png") no-repeat 4px 4px;
  float: left;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #999;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

#info_downloads_list_block .gps_data_download .empty {
  background: none;
  cursor: default;
}

#info_downloads_list_block .gps_data_download.active .access {
  background: #fff url("/images/accept_16x16_02.png") no-repeat 4px 4px;
}

#info_block_downloads .legend .accept,
#info_block_downloads .legend .decline,
#info_block_cities .legend .accept,
#info_block_cities .legend .decline {
  /*
  height: 30px;
  */
  padding-left: 20px;
  margin-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: url("/images/accept_16x16_02.png") no-repeat 0 8px;
  float: left;
}

#info_block_downloads .legend .decline,
#info_block_cities .legend .decline {
  background: url("/images/decline_16x16_03.png") no-repeat 0 8px;
  margin-right: 0;
}

#info_block_cities .legend {
  background-color: #ffffee;
}

#info_block_cities .legend {
  padding-left: 15px;
  background-color: #ffffee;
}

#info_block_cities .legend .accept,
#info_block_cities .legend .decline {
  background-color: #ffffee;
}

#info_block_cities .legend .accept .txt,
#info_block_cities .legend .decline .txt {
  padding-left: 10px;
  font-style: italic;
}

#info_downloads_list_block .gps_data_download .link {
  float: left;
}

#info_downloads_list_block .gps_data_download .link a {
  color: #666;
  font-weight: normal;
}

#info_downloads_list_block .gps_data_download.active .link a,
#info_downloads_list_block .gps_data_download.always_active .link a {
  color: #333;
  font-weight: bold;
}

#sidebar_all, #sidebar {
  width: 450px;
  width: 100%;
  background-color: #eee;
}

#sidebar_all {
  height: 28px;
  background: url("/images/poi_24x24.png") no-repeat 2px 2px;
  background-color: #fff;
  border-bottom: none;
}

#sidebar_all .text {
  margin-top: 3px;
  margin-left: 32px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  font-family: "OpenSansBold";
}

#sidebar_all .text:hover {
  color: #900;
}

#sidebar {
  height: 207px;
  overflow-x: hidden;
  overflow-y: auto;
}

#sidebar tr {
  height: 26px;
}

#sidebar td {
  text-align: left;
}

#loaddiv {
  height: 0px;
}

img .picture1 {
  border: 1px solid #abbfc1;
}

#main_title .txt {
  text-align: center;
}

#news_container .info_block {
  /*
  border: 2px solid #666;
  border: 2px solid #465b6b;
  */
  background-color: #eee;
  padding-bottom: 20px;
}

#news_container .info_block .title,
#news_container .info_block .title .title_item,
#news_container .info_block .title .title_divider {
  background-color: #666;
  background-color: #465b6b;
  color: #fff;
  font-size: 16px;
  font-family: "OpenSansSemibold";
  height: 24px;
}

#news_container .info_block .title {
  height: auto;
  padding: 0;
  margin: 0;
}

#news_container .info_block.links {
  /*
  margin-top: 22px;
  */
}

#news_container .info_block .title.links {
  background-color: #cfd4d5;
}

#news_container .info_block .title .title_item,
#news_container .info_block .title .title_divider {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

#news_container .info_block .title .title_item {
  border: 1px solid #465b6b;
  /*

  margin-top: -1px;

  margin-right: 4px;

  */
}

#news_container .info_block .title .title_item.inactive {
  background-color: #cfd4d5;
  color: #666;
}

#news_container .info_block .title .title_divider {
  background-color: #cfd4d5;
  width: 1px;
  margin-top: 0;
  padding-left: 3px;
  padding-right: 3px;
  width: 0;
  padding: 0;
}

#news_container .info_block .title .title_item:hover {
  background-color: #465b6b;
  color: #fff;
  border: 1px solid #465b6b;
}

#news_container .info_block .internal,
#gallery_countries .internal {
  padding: 5px;
  position: relative;
  margin: 0;
  padding-bottom: 10px;
}

#gallery_countries .internal,
#gallery_countries .internal a,
#gallery_countries .internal a:hover {
  text-decoration: none;
  box-shadow: none;
  border: none;
}

.cityinfo_item.issue_content_news_item1 {
  padding: 5px;
  display: inline-block;
}

#gallery_countries .internal.list_of_all_blocks {
  display: inline-block;
}

#news_container .info_block .list_of_all_blocks {
  /*
  display: none;
  z-index: -1;
  */
  /*
  position: absolute;
  min-height: 500px;
  */
}

/*
#news_container .info_block .internal.list_of_all_blocks {
	display: block;
}
*/
#news_container .info_block .info_block_cities_article_link {
  /*
  position: absolute;
  margin-top: -60px;
  */
}

#news_container .info_block .link_icon_article {
  cursor: pointer;
  float: left;
}

#news_container .info_cities_list .articles_title {
  font-family: "OpenSansBold";
  font-size: 18px;
  /*
  padding-left: 10px;
  */
}

#news_container .list_of_all_blocks .articles_body,
#news_container .info_cities_list .articles_body {
  /*
  padding-left: 10px;
  */
  font-size: 16px;
  text-align: left;
  padding-bottom: 0px;
}

#news_container .info_cities_list h3,
#news_container .info_cities_list .articles_body h3,
#news_container .articles_title h3 {
  font-size: 24px;
  font-family: "OpenSansBold";
  margin-top: 20px;
}

#news_container .articles_go_to_top {
  width: 32px;
  height: 32px;
  background: #fff url("/images/top_16x16.png") no-repeat 7px 7px;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #999;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin-top: 18px;
}

.cityinfo_block {
  display: none;
  background-color: #cfd4d5;
  border: 2px solid #fff;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.link_booking_map_html {
  background-color: #ededed;
}

.link_booking_map {
  cursor: pointer;
  min-height: 60px;
  background: #465b6b url("/images/map_48x48.png") no-repeat 6px 4px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
}

.link_booking_map .text {
  padding-left: 60px;
  padding-right: 8px;
  font-size: 18px;
  color: #fff;
}

.link_booking_map_html {
  display: none;
}

.info_fancybox {
  position: absolute;
  top: 70px;
}

#news_container .link_icons {
  margin: 0 auto;
  height: 32px;
}

#news_container .link_dates,
#news_container .link_places {
  clear: both;
}

#news_container .link_places .link_places_info,
#news_container .link_places .link_places_info_empty,
#news_container .link_places .link_places_title {
  float: left;
}

#news_container .link_places .link_places_info,
#news_container .link_places .link_places_info_empty {
  width: 34px;
  height: 34px;
  cursor: help;
}

#news_container .link_places .link_places_info_empty {
  cursor: default;
}

#news_container .link_places .link_places_title {
  /*

  width: 414px;

  width: 100%;

  */
  padding-top: 4px;
  padding-left: 4px;
}

.link_places_html .contents {
  font-size: 14px;
  /*

  margin-right: 30px;

  */
  padding: 10px;
  padding-right: 36px;
}

.link_places_html .contents b {
  font-size: 16px;
}

.link_places_html a {
  color: #990000;
  font-weight: bold;
}

.link_places_html a:hover {
  text-decoration: underline;
}

#news_container .link_footer a:hover {
  background-color: transparent;
}

#news_container .link_icon,
#news_container .link_icon_info {
  /*

  float: left;

  */
  cursor: pointer;
  padding: 7px;
  margin: 2px;
  background-color: #fff;
  border: 1px solid #999;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

#news_container .link_icon.lnk_icons_w3,
#news_container .link_icon_info.lnk_icons_w3,
#news_container .link_icon_article.lnk_icons_w3 {
  width: 32px;
}

#news_container .link_icon_info {
  cursor: pointer;
  cursor: help;
}

#news_container .link_icon.lnk_icons_w3,
#news_container .link_icon_info.lnk_icons_w3,
#news_container .link_icon_article.lnk_icons_w3 {
  width: 32px;
}

#news_container .link_icon.lnk_icons_w2,
#news_container .link_icon_info.lnk_icons_w2 {
  width: 48px;
  text-align: right;
}

#news_container .link_icon_info.lnk_icons_w2 {
  text-align: left;
}

#news_container .link_icon.lnk_icons_w1,
#news_container .link_icon_info.lnk_icons_w1,
#news_container .link_icon_article.lnk_icons_w1 {
  width: 96px;
}

#news_container .link_title,
#news_container .link_footer {
  /*

  font-family: 'OpenSansSemibold';

  */
  margin: 0;
  text-align: center;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  padding: 2px;
}

#news_container .link_footer {
  margin-top: -60px;
}

/*

.fancybox-overlay {

	top: 0;

	left: 0;

	right: 0;

	bottom: 0;

	width: 320px;

}

(*/
.info_cities_list .articles_body .hidden_related_items {
  display: none;
}

.info_cities_list .articles_body .more_related_items {
  display: block;
}

#country-articles {
  margin-top: 10px;
}

/*

.info_cities_list .articles_body .more_link {

	font-size: 16px;

	color: #0070c0;

	cursor: pointer;

	font-style: italic;

	padding-top: 0px;

	padding-left: 18px;

	font-weight: bold;

}

.info_cities_list .articles_body .more_link:hover {

	color: $color_darkred;

}

*/
#news_container .articles_body a,
.info_cities_list .articles_body a,
#news_container .modal-internal a {
  font-size: 16px;
  color: #0070c0;
  text-decoration: none;
}

#news_container .articles_body a:hover,
.info_cities_list .articles_body a:hover,
#news_container .modal-internal a:hover {
  color: #900;
}

#news_container .modal-internal {
  background-color: #fff;
  text-align: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#news_container .city_info_icon {
  width: 32px;
  background: url("/images/finger_info_32x32_03.png") no-repeat 0 0;
}

#news_container .city_info_accommodation {
  clear: both;
  width: auto;
}

#news_container .city_info_icon,
#news_container .city_info_name {
  height: 32px;
  float: left;
  cursor: pointer;
}

#news_container .city_info_name {
  padding-left: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#news_container .city_info_accommodation .vue-picture-swipe-feat {
  padding-top: 5px;
  clear: both;
}

/******************

*                 *

*   GOOGLE MAPS   *

*                 *

*******************/
.map {
  width: 100%;
  height: 400px;
}

.marker-list {
  max-height: 128px !important;
  overflow-y: auto;
}

.marker-list-item {
  color: #333;
  background-color: #eee;
  text-align: left;
  border: 0px solid #999;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
}

.marker-list-item-icon {
  width: 38px;
}

.marker-list-item-title {
  padding-left: 5px;
}

.marker-list-item-icon,
.marker-list-item-title {
  float: left;
  height: 100%;
}

.marker-list-item .marker-list-item-icon {
  background: #eee url("/images/city_32x32_01.png") no-repeat 2px 0;
}

.marker-list-item.kml .marker-list-item-icon {
  background: #eee url("/images/marker_32x32_navigate.png") no-repeat 2px 0;
}

.marker-list-item:hover {
  color: #fff;
  background-color: #465b6b;
}

.accommodation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accommodation-modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.accommodation-modal-content > * + * {
  margin: 10px 0;
}

.accommodation-modal-content h1 {
  font-size: 16px;
}

.accommodation-modal-content button {
  margin: 0 auto;
  padding: 5px 15px;
  border: 1px solid;
  border-radius: 5px;
  color: #fff;
  background-color: #465b6b;
}

.dark .accommodation-modal-content {
  background: #000;
}

@font-face {
  font-family: "HP Simplified Bd";
  src: url("/fonts/HPSimplified.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified Rg";
  src: url("/fonts/HPSimplified_Rg.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified BdIt";
  src: url("/fonts/HPSimplified_BdIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "HP Simplified It";
  src: url("/fonts/HPSimplified_It.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "HP Simplified Lt";
  src: url("/fonts/HPSimplified_Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "HP Simplified LtIt";
  src: url("/fonts/HPSimplified_LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "HP Simplified";
  src: url("/fonts/HPSimplified_Lt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* font-family: "OpenSansRegular"; */
@font-face {
  /*

  font-family: "OpenSansRegular";

  */
  font-family: "OpenSans";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansRegular/OpenSansRegular.eot");
  src: url("/fonts/opensans/OpenSansRegular/OpenSansRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("/fonts/opensans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansLight"; */
@font-face {
  font-family: "OpenSansLight";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansLight/OpenSansLight.eot");
  src: url("/fonts/opensans/OpenSansLight/OpenSansLight.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansLight/OpenSansLight.woff") format("woff"), url("/fonts/opensans/OpenSansLight/OpenSansLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansItalic"; */
@font-face {
  font-family: "OpenSansItalic";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansItalic/OpenSansItalic.eot");
  src: url("/fonts/opensans/OpenSansItalic/OpenSansItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansItalic/OpenSansItalic.woff") format("woff"), url("/fonts/opensans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansBold"; */
@font-face {
  font-family: "OpenSansBold";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansBold/OpenSansBold.eot");
  src: url("/fonts/opensans/OpenSansBold/OpenSansBold.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansBold/OpenSansBold.woff") format("woff"), url("/fonts/opensans/OpenSansBold/OpenSansBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansExtraBold"; */
@font-face {
  font-family: "OpenSansExtraBold";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.eot");
  src: url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"), url("/fonts/opensans/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansExtraBoldItalic"; */
@font-face {
  font-family: "OpenSansExtraBoldItalic";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot");
  src: url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff") format("woff"), url("/fonts/opensans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansBoldItalic"; */
@font-face {
  font-family: "OpenSansBoldItalic";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
  src: url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"), url("/fonts/opensans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansRegularItalic"; */
@font-face {
  font-family: "OpenSansRegularItalic";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.eot");
  src: url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.woff") format("woff"), url("/fonts/opensans/OpenSansRegularItalic/OpenSansRegularItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansSemiBold"; */
@font-face {
  font-family: "OpenSansSemiBold";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.eot");
  src: url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"), url("/fonts/opensans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "OpenSansSemiBoldItalic"; */
@font-face {
  font-family: "OpenSansSemiBoldItalic";
  font-display: swap;
  src: url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot");
  src: url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff") format("woff"), url("/fonts/opensans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
.gallery_pics {
  width: 170px;
  border-radius: 10px;
}

.fancybox-caption {
  padding: 5px !important;
  text-align: center;
  margin-bottom: 0;
}

.fancybox-caption::before {
  display: inline-block;
  box-sizing: border-box;
}

.likes-html-template {
  display: none;
  z-index: 999999999999;
  background: red;
}

section#tillypad_news .col {
  background-color: #ffffee;
  border: 1px solid #666;
}

section#tillypad_news .item,
section#tillypad_news .news {
  border: 1px solid #fff;
  background-color: #fff;
  color: #666;
  height: 50px;
  height: auto;
}

section#tillypad_news .news {
  height: 156px;
  padding-left: 20px;
}

section#tillypad_news .news .text {
  height: 80px;
  overflow-y: hidden;
  line-height: 16px;
}

section#tillypad_news .news .date {
  height: 20px;
  line-height: 20px;
  font-size: 11px;
}

section#tillypad_news .news .link {
  height: 40px;
  line-height: 40px;
  font-size: 11px;
}

section#tillypad_news .news .link a:hover {
  color: #ff7c24;
}

section#tillypad_news .news .title {
  height: 24px;
  overflow: hidden;
  font-size: 16px;
  font-family: "OpenSansSemibold";
}

section#tillypad_news .news .row:hover .title {
  color: #ff7c24;
}

section#tillypad_slider,
section#tillypad_news {
  padding: 0;
}

section#tillypad_news .item {
  display: inline-block;
  overflow: hidden;
}

section#tillypad_news .item .row {
  position: absolute;
  bottom: 0;
}

section#tillypad_news a {
  color: #465B6B;
}

section#tillypad_slider {
  background-color: #666;
  background-color: #007882;
}

section#tillypad_slider a {
  text-decoration: none;
}

section#tillypad_slider .carousel-caption.outside {
  position: relative;
  margin-top: 20px;
  height: 150px;
  /*
  left: auto;
  right: auto;
  float: none;
  margin: 20px auto;


  left: 50%;
  margin-left: -35%;
  width: 70%;

  */
}

section#tillypad_slider .carousel-caption.inside {
  /*
   position: absolute;

   right: auto;

   text-align: left;
   height: auto;
   vertical-align: center;


   float: none;

   top: 50%;
  transform: translateY(-50%);
  */
}

section#tillypad_slider .carousel-caption.inside {
  bottom: 0;
  top: 0;
  height: 100%;
}

section#tillypad_slider .carousel-caption .carousel_text {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
}

section#tillypad_slider .carousel-caption.outside .carousel_text {
  width: 70%;
  text-align: center;
  /*
  background-color: pink;
  */
}

section#tillypad_news .links_title {
  margin-top: 123px;
  height: 33px;
  width: 100%;
}

section#tillypad_news .links_title .title {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  font-size: 16px;
}

section#tillypad_news .links_title:hover .title {
  color: #ff7c24;
}

#news_container a.not_active_link,
#news_container a.not_active_link img,
#news_container a.not_active_link div {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /*
  filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
  */
  cursor: default;
}

#news_container a.not_active_link img {
  opacity: 0.5;
  /*
  filter: alpha("opacity=50");
  */
}

#news_container .issue_content_news_item .flag {
  position: absolute;
  top: 32px;
  left: 50%;
  margin-left: -85px;
  cursor: pointer;
  width: 170px;
  height: 114px;
}

#news_container .issue_content_news_item .flag.full {
  top: 8px;
}

#news_container .issue_content_news_item {
  height: auto;
  /*
  overflow: hidden;
  */
}

#news_container .issue_content_news_item .exif_info,
#news_container .issue_content_news_item .text_info,
#news_container_gallery .issue_content_news_item .exif_info,
#news_container_gallery .issue_content_news_item .text_info {
  display: none;
}

#news_container #gallery_item_exif_info_icon,
#news_container #gallery_item_text_info_icon {
  width: 36px;
  height: 36px;
  z-index: 8040;
  display: none;
  background: url("/images/exif_info.png") no-repeat 0 0;
}

#news_container .issue_content_news_item .no_title {
  /*
  height: 154px;
  height: 140px;
  */
}

#news_container .issue_content_news_item .img_big {
  display: none;
  margin: 0 auto;
}

#news_container .issue_content_news_item .flag .img_big_div {
  position: absolute;
  top: -32px;
  z-index: 1;
  text-align: center;
  height: 138px;
  width: 208px;
  overflow: hidden;
  left: 50%;
  margin-left: -104px;
}

#news_container .issue_content_news_item .flag.full .img_big_div {
  top: -8px;
  height: 150px;
}

#news_container .issue_content_news_item .img_small {
  display: block;
  margin: 0 auto;
}

#news_container .issue_content_news_item .img_small.no_title_img {
  margin: 4px auto;
}

#news_container .issue_content_news_item_list {
  text-align: left;
  display: none;
  margin-left: 15px;
  margin-top: 0;
}

#news_container .issue_content_news_item_list div a {
  font-size: 16px;
}

#news_container .issue_content_news_item_title {
  position: absolute;
  width: 210px;
  height: 27px;
  font-size: 17px;
  cursor: pointer;
  margin-left: -5px;
}

#news_container .issue_content_news_item_blank {
  height: 155px;
}

#news_container .issue_content_news_item_blank_short {
  height: 140px;
}

#news_container .issue_content_news_item_subtitle {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 138px;
  /*
  top: 0;
  */
  width: 208px;
  height: 27px;
  background: url("/images/semitransparent.png") repeat 0 0;
  display: none;
  z-index: 2;
  font-size: 17px;
  line-height: 23px;
  color: #fff;
}

#news_container .issue_content_news_item,
#news_container .issue_content_news_item.vis,
#news_container .issue_content_news_item.invis {
  border: 1px solid #cfd4d5;
  background-color: #cfd4d5;
  border: 1px solid #abbfc1;
  background-color: #eee;
  height: 167px;
  height: auto;
}

#news_container .issue_content_news_item .img_big_div .img_hotinfo_div {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8080;
  width: 232px;
  height: 140px;
}

#news_container .issue_content_news_item .img_big_div .img_hotinfo_div .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-box-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "OpenSansBold";
  background: rgba(255, 0, 0, 0.7);
  border: 1px #fff solid;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 2px;
  width: 160px;
  background-color: #666;
  color: #fff;
  border: 2px #fff solid;
}

/*
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
	background-image: url("/images/fancybox_sprite.png");
	z-index: 9999;
	position: absolute;
	display: block;
}
*/
#news_container .issue_content_news_items {
  position: relative;
}

#news_container {
  position: relative;
}

/***********************************
#	!! @breakpoint_xs:   0;      !! #
#	!! @breakpoint_sm:   576px;  !! #
#	!! @breakpoint_md:   768px;  !! #
#	!! @breakpoint_lg:   992px;  !! #
#	!! @breakpoint_xl:   1126px; !! #
***********************************/
@media all and (max-width: 1200px) {
  section#tillypad_news .news {
    height: auto;
    padding: 10px;
  }
}
/*
//=============//
//             //
//   RADIUS    //
//             //
//=============//
*/
#news_container_index .issue_content_news_item .inner,
#news_container_index .issue_content_news_item .inner .img_big_div {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

#news_container_index .issue_content_news_item .inner .img_big_div img,
#news_container_gallery .img_big_div img {
  width: 100%;
}

#news_container img.link_image,
#gallery_countries img.link_image {
  width: 170px;
}

#news_container_index .issue_content_news_item .inner .img_big_div img,
#news_container_gallery .img_big_div img,
#news_container .link_title {
  border-radius: 10px 10px 0 0;
}

#news_container_index .issue_content_news_item .inner .issue_content_news_item_subtitle,
#news_container_gallery .issue_content_news_item .inner .issue_content_news_item_subtitle,
#news_container img.link_image,
#gallery_countries img.link_image,
#news_container .link_footer,
#news_container_gallery .img_big_div {
  border-radius: 0 0 10px 10px;
}

#index_sitemap_block .sitemap_body,
#news_container .info_block,
#index_info_block .info_body,
#blog_sitemap_block .sitemap_body {
  border-radius: 10px;
}

.sitemap_caption,
.info_caption,
nav.navbar .ul .li .text,
#blog_search_block .s_text,
#blog_search_block .s_input,
.issue_content_blog_item .flag img,
.gallery_contents_item.very_small_pics img,
.gallery_contents_item.very_small_pics,
#news_container_gallery .flag_likes {
  border-radius: 5px;
}

.index_banner_place {
  border: none;
}

.index_banner_place img,
.blog_accommodation_country {
  border-radius: 10px;
}

.blog_accommodation_country .name {
  border-radius: 0 5px 5px 0;
}

.blog_accommodation_country .icon {
  border-radius: 5px 0 0 5px;
}

#index_sitemap_block,
#index_info_block,
#map_data {
  padding-left: 15px;
  padding-right: 15px;
}

#index_sitemap_block .sitemap_caption,
#index_info_block .info_caption {
  padding: 5px;
  padding-left: 10px;
  text-align: left;
  background-color: #465b6b;
  color: #fff;
  font-weight: bold;
}

#index_sitemap_block .sitemap_body,
#index_info_block .info_body {
  padding: 10px;
  border: 1px solid #abbfc1;
  background-color: #eee;
}

#index_sitemap_block .sitemap_item1,
#index_info_block .info_item1 {
  padding-left: 0;
}

#index_sitemap_block .sitemap_item2,
#index_info_block .info_item2 {
  padding-left: 10px;
}

#news_container p,
#index_sitemap_block .passive_link,
#index_sitemap_block .active_link,
#index_sitemap_block .sitemap_item2,
#index_info_block .passive_link,
#index_info_block .active_link,
#index_info_block .info_item2 {
  font-size: 16px;
}

#index_sitemap_block .sitemap_caption,
#index_sitemap_block .sitemap_item1,
#index_info_block .info_caption,
#index_info_block .info_item1 {
  font-size: 18px;
}

#index_sitemap_block .passive_link,
#index_info_block .passive_link {
  color: #999;
  font-style: italic;
}

#index_sitemap_block .active_link:hover,
#index_info_block .active_link:hover {
  background-color: #fff;
  color: #000;
}

#yandex_rtb_block,
#google_adsense_block {
  padding-left: 9px;
  padding-right: 9px;
  opacity: 0.333;
}

#yandex_rtb_block:hover,
#google_adsense_block:hover {
  opacity: 1;
}

#vector_map_place {
  width: 450px;
  height: 300px;
  background-color: #383f47;
}

#vector_map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#vector_map_overlay {
  overflow: hidden;
}

#vector_map_overlay svg {
  position: absolute;
}

#vector_map_place .settings {
  display: none;
}

#vector_map_place .backgroundColor {
  color: #84b4e4;
}

#vector_map_place .markerStyle_initial_fill {
  color: #00ff00;
}

#vector_map_place .markerStyle_selected_fill {
  color: #ca0020;
}

#vector_map_place .regionStyle_initial_fill {
  color: #fff;
}

#vector_map_place .regionStyle_selected_fill {
  color: #b8e186;
}

#vector_map_place .regionStyle_selectedHover_fill {
  color: #009900;
}

#vector_map_place .series_regions_scale1 {
  color: #c8eeff;
}

#vector_map_place .series_regions_scale2 {
  color: #0071a4;
}

#vector_map_place .series_regions_scale_visited {
  color: #b8e186;
}

#vector_map_place .series_regions_scale_myhome {
  color: #fcbeab;
}

#vector_map_place .series_regions_scale_other {
  color: #fff;
}

#vector_map_place {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #84b4e4;
  overflow: hidden;
  z-index: 1;
  margin-top: 20px;
}

#vector_map_routes {
  position: relative;
  padding: 5px;
  text-align: left;
  background-color: #eee;
  border: 1px solid #abbfc1;
  margin-top: 20px;
}

#vector_map_routes a {
  color: #465b6b;
}

#vector_map_routes a:hover {
  color: #990000;
}

#vector_map_routes .link,
#vector_map_routes .distance {
  font-family: OpenSansBold;
  font-size: 16px;
  cursor: pointer;
  line-height: 20px;
}

#vector_map_routes .link:hover {
  color: #990000;
}

#vector_map,
#vector_map_place .uk_countries_mill,
#vector_map_place .ru_mill,
#vector_map_place .ru_fd_mill {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  opacity: 0.8;
  filter: alpha(Opacity=80);
}

#vector_map_place .close_btn {
  margin-top: 5px;
  margin-left: 5px;
  width: 26px;
  height: 26px;
  background: transparent url("/images/icon_plus.png?0") 0 0;
  z-index: 100;
  cursor: pointer;
}

.svgMapOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
}

#distance_and_directions {
  position: relative;
  padding: 10px;
  text-align: left;
}

#distance_and_directions .td {
  padding-left: 5px;
  padding-right: 5px;
}

#distance_and_directions .th {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #666;
  color: #eee;
  text-align: left;
}

#distance_and_directions .right {
  text-align: right;
}

#distance_and_directions .bg_light {
  background-color: #eee;
}

#distance_and_directions .bg_dark {
  background-color: #ddd;
}

#distance_and_directions .bg_light.bg_future {
  background-color: #eee9df;
}

#distance_and_directions .bg_dark.bg_future {
  background-color: #e2d9c7;
}

#distance_and_directions .total {
  font-size: 16px;
  font-family: OpenSansBold;
  font-weight: bold;
  background-color: #666;
  color: #eee;
}

#distance_and_directions .td_right {
  text-align: right;
  font-weight: bold;
}

#distance_and_directions .td_right.red {
  color: #ffff00;
}

/***********************************
#	!! @breakpoint_xs:   0;      !! #
#	!! @brkpnt_min:      321px;  !! #
#	!! @breakpoint_sm:   576px;  !! #
#	!! @brkpnt_sm_1:     575px;  !! #
#	!! @breakpoint_md:   768px;  !! #
#	!! @brkpnt_md_1:     767px;  !! #
#	!! @breakpoint_lg:   992px;  !! #
#	!! @breakpoint_xl:   1200px; !! #
***********************************/
@media all and (max-width: 768px) {
  #vector_map_place {
    height: 400px;
  }
}
@media all and (max-width: 576px) {
  #vector_map_place {
    height: 400px;
  }
}
@media all and (max-width: 321px) {
  #vector_map_place {
    height: 400px;
  }
}
#vector_map_place .settings {
  display: none;
}

#vector_map_place .backgroundColor {
  color: #84b4e4;
}

#vector_map_place .markerStyle_initial_fill {
  color: #ccc;
  color: #ffc;
}

#vector_map_place .markerStyle_selected_fill {
  color: #ca0020;
}

#vector_map_place .markerStyle_selectedHover_fill {
  color: #fff;
}

#vector_map_place .regionStyle_initial_fill {
  color: #fff;
}

#vector_map_place .regionStyle_selected_fill {
  color: #00f;
}

#vector_map_place .regionStyle_selectedHover_fill {
  color: #009;
}

#vector_map_place .series_regions_scale1 {
  color: #c8eeff;
}

#vector_map_place .series_regions_scale2 {
  color: #0071a4;
}

.here_home {
  background-color: #f00;
  color: #fff;
}

.here_visited {
  background-color: #00f;
  color: #fff;
}

.here_others {
  background-color: #fff;
  color: #1a242e;
  color: #000;
}

.here_plans {
  background-color: #ffc657;
  color: #1a242e;
}

.here_table {
  /*
  border: 1px solid #bbb;
  */
  width: 100%;
}

.here_col1 {
  text-align: left;
  padding-left: 10px;
}

.here_col2 {
  text-align: right;
  padding-right: 10px;
}

#vector_map_place .series_regions_scale_visited {
  color: #00f;
}

#vector_map_place .series_regions_scale_plans_planning {
  color: #ff0;
  color: #ffc657;
}

#vector_map_place .series_regions_scale_myhome {
  color: #f00;
}

#vector_map_place .series_regions_scale_myhomeHover {
  color: #900;
}

#vector_map_place .series_regions_scale_other {
  color: #fff;
}

.h5, h5 {
  font-size: 32px;
}

.h6, h6 {
  font-size: 24px;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
  text-align: center;
}

section#tillypad_carousel {
  height: 80px;
}

section#tillypad_contents .title a,
section#tillypad_contents h1 {
  font-size: 30px;
  color: #465b6b;
  font-family: "OpenSans";
}

section#tillypad_contents .title a:hover {
  color: #ff7c24;
}

section#tillypad_contents .subtitle,
section#tillypad_contents .subtitle_green,
section#tillypad_contents .title_green,
section#tillypad_contents h2 {
  font-family: "OpenSansLight";
  color: #000;
  font-size: 18px;
  line-height: 28px;
}

section#tillypad_contents .subtitle_green,
section#tillypad_contents .title_green {
  color: #007882;
}

section#tillypad_contents .subtitle_green.error {
  font-size: 14px;
}

section#tillypad_contents .title_green {
  font-size: 34px;
  line-height: 44px;
  padding-left: 9px;
}

section#tillypad_contents a.subtitle_green_small:hover,
section#tillypad_contents a.subtitle_green:hover,
section#tillypad_contents .main_text a:hover {
  color: #ff7c24;
}

section#tillypad_contents .subtitle h1,
section#tillypad_contents .subtitle h2 {
  font-family: "OpenSansLight";
  color: #000;
  font-size: 24px;
  line-height: 30px;
}

section#tillypad_contents .subtitle a h1,
section#tillypad_contents .subtitle a h2 {
  color: #007882;
}

section#tillypad_contents .main_text,
section#tillypad_contents td,
section#tillypad_contents li,
section#tillypad_contents p,
section#tillypad_contents .more_link a,
section#tillypad_contents .subtitle_green_small,
section#tillypad_contents .main_text a,
section#tillypad_contents .news_next_link a,
section#tillypad_contents .news_prev_link a {
  font-family: "OpenSans";
  color: #000;
  font-size: 14px;
  line-height: 24px;
}

section#tillypad_contents .news_next_link,
section#tillypad_contents .news_prev_link {
  padding-left: 9px;
  padding-right: 9px;
}

section#tillypad_contents .news_next_link .in,
section#tillypad_contents .news_prev_link .in {
  padding-left: 20px;
  padding-right: 20px;
  background: url("/images/arrow_next_small.png") no-repeat center right;
}

section#tillypad_contents .news_prev_link .in {
  background: url("/images/arrow_prev_small.png") no-repeat center left;
}

section#tillypad_contents .subtitle_green_small,
section#tillypad_contents .main_text a,
section#tillypad_contents .news_next_link a,
section#tillypad_contents .news_prev_link a {
  font-family: "OpenSansLight";
  color: #007882;
}

section#tillypad_contents .more_link a {
  font-family: "OpenSansLight";
  color: #007882;
}

section#tillypad_contents .more_link a:hover {
  color: #ff7c24;
}

section#tillypad_contents .title,
section#tillypad_contents .subtitle,
section#tillypad_contents .more_link,
section#tillypad_contents .main_text,
section#tillypad_contents .others {
  padding-left: 9px;
  padding-right: 9px;
}

section#tillypad_contents .main_text_link a {
  font-size: 12px;
}

section#tillypad_contents .divider_wrap {
  padding-left: 9px;
  padding-right: 9px;
  height: 1px;
}

section#tillypad_contents .divider_wrap .divider {
  background-color: #abbfc1;
  height: 1px;
  width: 100%;
}

section#tillypad_contents #news_loading {
  display: none;
}

section#tillypad_contents .main_text_block,
section#tillypad_contents .main_text_link {
  padding: 0;
}

section#tillypad_contents .main_text .subtitle {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

section#tillypad_contents .row_h20 {
  height: 20px;
}

section#tillypad_contents .carousel-indicators li {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #007882;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

section#tillypad_contents .carousel-indicators li.active {
  border: 1px solid #fff;
  border: 1px solid #007882;
  background-color: #007882;
}

section#tillypad_contents #news_posts .item {
  padding: 0;
}

section#tillypad_contents .image_title {
  font-size: 12px;
  color: #666;
  font-style: italic;
}

section#tillypad_contents .carousel-caption.outside {
  position: relative;
  margin-top: 20px;
  margin-top: 10px;
  height: 50px;
  height: auto;
  /*
  background-color: pink;

  left: auto;
  right: auto;
  float: none;
  margin: 20px auto;


  left: 50%;
  margin-left: -35%;
  width: 70%;

  */
}

section#tillypad_contents .carousel-caption.inside {
  /*
   position: absolute;

   right: auto;

   text-align: left;
   height: auto;
   vertical-align: center;


   float: none;

   top: 50%;
  transform: translateY(-50%);
  */
}

section#tillypad_contents .carousel-caption.inside {
  bottom: 0;
  top: 0;
  height: 100%;
}

section#tillypad_contents .carousel-caption .carousel_text {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
}

section#tillypad_contents .carousel-caption.outside .carousel_text {
  width: 70%;
  text-align: center;
  /*
  background-color: pink;
  */
}

section#tillypad_contents .carousel-caption.outside .carousel_text h5 {
  font-size: 12px;
  color: #666;
  font-style: italic;
}

section#tillypad_contents #issue_untranslated_panel {
  display: none;
}

section#tillypad_contents .issue_content_item {
  clear: both;
  width: 100%;
}

section#tillypad_contents #issues_icon_pdf_links1_hover {
  display: none;
}

section#tillypad_contents .big_title {
  font-size: 30px;
  color: #007882;
}

#news_container_gallery,
#news_container_index {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

#news_container_gallery .issue_content_news_item,
#news_container_index .issue_content_news_item {
  padding-left: 5px;
  padding-right: 5px;
}

#news_container_gallery .issue_content_news_item_subtitle,
#news_container_index .issue_content_news_item_subtitle {
  background-color: #333;
  color: #fff;
  margin-bottom: 15px;
  font-size: 16px;
  padding: 5px;
}

#news_container_gallery .issue_content_news_item_blank_short {
  margin-bottom: 15px;
}

#news_container_index .issue_content_news_item .inactive {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /*
  filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
  */
  cursor: default;
  opacity: 0.5;
}

#news_container_index .issue_content_news_item .main_hotinfo_div1,
#news_container_gallery .issue_content_news_item .main_hotinfo_div1 {
  position: absolute;
  display: none;
  margin-top: -150px;
  width: 100%;
  /*
  opacity: 1;

  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  */
}

#news_container_gallery .issue_content_news_item .main_hotinfo_div1 {
  /*
  display: block;
  */
}

#news_container_index .issue_content_news_item .img_hotinfo_div1 .text1,
#news_container_gallery .issue_content_news_item .img_hotinfo_div1 .text1 {
  font-family: "OpenSansBold";
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  background-color: #666;
  color: #fff;
  border: 2px #fff solid;
}

#news_container_gallery .issue_content_news_item .img_hotinfo_div1 .text1 {
  font-family: "OpenSansSemibold";
  padding: 2px;
  font-size: 16px;
  line-height: 17px;
  background-color: #990000;
  color: #fff;
  border: 2px #fff solid;
  font-weight: normal;
}

*:focus, button:focus, textarea:focus, input:focus {
  outline: none;
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*
.fancybox-wrap {
	z-index: 999999999999;
}
*/
.ui-select span {
  display: none;
}

html, body, div, span, table, tr, td, p, textarea, input {
  font-family: "OpenSans";
  font-size: 16px;
}

html {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  /*
  height: 100%;
  */
}

body {
  -ms-touch-action: none;
}

:root {
  --blue: #fff;
  --primary: #fff;
}

html, body {
  overflow-x: hidden;
  font-size: 1em;
}

body {
  overflow-y: hidden;
}

div {
  /*
  border: 1px solid red;
  */
}

/*
.row.no-gutters {
	width: 100%;
}
*/
.div_0px,
.div_3px,
.div_5px,
.div_10px,
.div_15px,
.div_20px,
.div_30px,
.div_40px,
.div_50px {
  width: 100%;
  clear: both;
}

.div_0px {
  height: 0;
}

.div_3px {
  height: 3px;
}

.div_5px {
  height: 5px;
}

.div_10px {
  height: 10px;
}

.div_15px {
  height: 15px;
}

.div_20px {
  height: 20px;
}

.div_30px {
  height: 30px;
}

.div_40px {
  height: 40px;
}

.div_50px {
  height: 50px;
}

a, a:hover {
  color: #465b6b;
  text-decoration: none;
}

p {
  font-size: 13px;
}

/***********************

	OVERLAY BLOCK

***********************/
#overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
  display: none;
}

/*****************

   MAIN

*****************/
#mozolin_main {
  position: absolute;
  position: relative;
  left: 50%;
  margin-left: -600px;
  width: 1200px;
  border-left: 1px solid #abbfc1;
  border-right: 1px solid #abbfc1;
}

#mozolin_main .row.empty {
  height: 0px;
}

/*****************

   TOP

*****************/
section#mozolin_top {
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #fff;
}

section#mozolin_top .lang_place {
  /*
  width: 80px;
  height: 30px;
  margin-top: 5px;
  */
  float: right;
  margin-right: -10px;
}

section#mozolin_top .row {
  /*
  padding: 0;
  */
  margin: 0;
}

section#mozolin_top .slogan {
  padding-top: 2px;
  padding-left: 5px;
}

section#mozolin_top .lang_outer, section#mozolin_top .lang_outer_active {
  height: 20px;
  float: right;
  margin-left: 9px;
  cursor: pointer;
  border: solid #007882 1px;
}

section#mozolin_top .lang_outer {
  border: solid #fff 1px;
}

section#mozolin_top .lang_outer .lang:hover {
  color: #ff7c24;
}

section#mozolin_top .lang_outer_active .lang, section#mozolin_top .lang_outer .lang {
  height: 16px;
  margin-top: -3px;
  font-family: "OpenSansSemibold";
  font-size: 16px;
  color: #007882;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.untrans_text {
  display: none;
}

/*****************

   NAVIGATION

*****************/
nav.navbar {
  color: #465b6b;
  margin: 0;
  padding: 0;
  /* shadow */
  /*
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
   filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
   */
}

nav .navbar-toggler-icon {
  border: none;
  width: 30px;
  /*
   height: 22px;
  */
  background: url("/images/menu.png") 0 0 no-repeat;
  width: 60px;
  height: 60px;
  background: url("/images/mobile_menu2_button.png") no-repeat 0 0;
}

nav .logo_phone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

nav .logo_phone .logo {
  width: 144px;
  height: 32px;
  /*
  background:url("/images/tillypad_logo_feb14.png");
   */
  position: absolute;
  cursor: pointer;
  /*
  margin-top: 7px;
  margin-left: 11px;
  */
  top: 0;
}

nav .logo_phone .phone {
  position: absolute;
  top: 0;
  right: 0;
  /*
  margin-right: 11px;
  margin-top: 7px;
  */
}

nav .menu {
  cursor: pointer;
  margin-right: 10px;
}

nav .nav-link {
  padding: 0;
}

nav.navbar .dropdown-menu.show .submenu_rows {
  padding: 15px;
  padding-bottom: 10px;
}

nav.navbar .dropdown-menu.show .submenu_cols {
  /*
  padding-top: 5px;
  */
  padding-bottom: 5px;
}

/*
nav.navbar .dropdown-menu.show .submenu_cols,
nav.navbar .dropdown-menu.show .submenu_cols:focus,
nav.navbar .dropdown-menu.show .submenu_cols:active,
nav.navbar .dropdown-menu.show .submenu_cols::after,
nav.navbar .dropdown-menu.show .submenu_cols::before,
nav.navbar .dropdown-menu.show .submenu_cols:hover
{
	background-color: pink;
}
*/
.dropdown-menu {
  background-color: #fff !important;
  /* shadow */
  /*
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
   filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
   filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
   */
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus {
  background-color: #fff !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #fff;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 9px;
  padding-right: 9px;
}

nav .underline {
  height: 2px;
}

nav .underline.inactive {
  background-color: #fff;
}

nav .menu:hover .underline.inactive {
  background-color: #465b6b;
}

nav .underline.active {
  background-color: #465b6b;
}

nav.navbar .ul {
  position: relative;
  margin-top: 48px;
  height: auto;
  padding-left: 10px;
}

nav.navbar .ul > .li {
  position: static;
  z-index: 1010;
  padding: 0;
}

nav.navbar .ul .li .text {
  font-family: "OpenSansSemibold";
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  border: 1px solid #abbfc1;
  background-color: #cfd4d5;
  padding-left: 15px;
  padding-right: 15px;
}

nav.navbar .ul .li .text:hover {
  border: 1px solid #eee;
  border: 1px solid #465b6b;
  background-color: #465b6b;
  color: #fff;
}

nav.navbar .ul > .li .dropdown-menu {
  width: 100%;
  margin-left: -16px;
  transform: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  /*
  width: $brkpnt_xl;
  */
}

nav.navbar .ul .li .dropdown-menu.show {
  margin-top: 2px;
  margin-top: 0;
}

nav.navbar .dropdown a {
  color: #465b6b;
}

nav.navbar .dropdown-menu a {
  color: #007882;
}

.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #357EBD;
  background-image: linear-gradient(to bottom, #428BCA 0, #357EBD 100%);
}

nav .media {
  cursor: pointer;
  color: #007882;
}

nav .media:hover {
  color: #ff7c24;
}

nav .media:hover img.default {
  display: none;
}

nav .media img.hover {
  display: none;
}

nav .media:hover img.hover {
  display: block;
}

/*****************

   FOOTER

*****************/
section#mozolin_bottom {
  padding: 0;
  background-color: #fff;
  border-top: 1px solid #abbfc1;
}

#mozolin_footer .row {
  /*
  padding: 15px;
  */
}

#mozolin_footer .column {
  height: 25px;
}

#mozolin_footer .icon_bar_img img {
  margin-left: 5px;
}

#disabled_body {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999999;
  background-color: #666;
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  display: none;
}

::selection {
  background-color: #666;
  color: #fff;
}

::-moz-selection {
  background-color: #666;
  color: #fff;
}

::-webkit-selection {
  background-color: #666;
  color: #fff;
}

body, input {
  margin: 0;
  padding: 0;
  background-color: #eee;
  color: #465b6b;
  /*
  height: 100%;
  */
}

img {
  border: 0;
}

a {
  color: #666;
  text-decoration: none;
}

a:hover {
  color: #990000;
}

.main_page_block,
.main_title {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #cfd4d5;
  clear: both;
}

.main_page_block a,
.main_page_block a:hover {
  text-decoration: underline;
  text-decoration: none;
}

.main_title {
  border-top: 1px solid #abbfc1;
}

#main_footer_block {
  position: relative;
  /*
  bottom: 0;
  left: -1px;
  height: 60px;

  border-left: 1px solid #abbfc1;
   border-right: 1px solid #abbfc1;
  */
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #abbfc1;
  font-size: 16px;
}

#main_footer_block .version_info {
  display: none;
  border: 1px #abbfc1 solid;
  border: 1px #666 solid;
  background-color: #ffffee;
  background-color: #eee;
  padding: 5px;
  color: #666;
  position: relative;
  font-size: 10px;
  margin-left: 5px;
  font-family: "OpenSansSemiBold";
}

#main_footer_block .copyright {
  cursor: pointer;
}

#main_footer_block .copyright,
#main_footer_block .email {
  font-size: 16px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  text-align: left;
}

#main_footer_block .copyright1 {
  font-size: 16px;
  color: #465b6b;
}

#main_footer_block .promo_block,
#main_footer_block .promo,
#main_footer_block .promo_right,
#main_footer_block .footer_empty {
  font-size: 16px;
  /*
  float: left;
  margin-left: 10px;
  margin-top: 14px;
  */
  padding-top: 9px;
  padding-bottom: 9px;
}

#main_footer_block .promo {
  padding-left: 3px;
  padding-right: 3px;
}

#main_footer_block .footer_empty {
  margin-left: 40px;
}

#main_footer_block .promo_right {
  float: right;
  margin-right: 10px;
}

#main_title {
  position: relative;
  /*
  margin-top: 15px;
  */
}

#main_title h1 {
  font-size: 32px;
  color: #465b6b;
  font-family: "OpenSansSemibold";
  padding-left: 15px;
  padding-right: 15px;
}

#news_container {
  position: relative;
  /*
  margin-top: 15px;
  */
}

#banner_booking_com,
#banner_booking_join_com,
#banner_airbnb_com,
#banner_skyscanner_ru,
#banner_avia_yandex_ru {
  /*
  margin: 15px auto;
  */
  width: 468px;
  height: 60px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #abbfc1;
  border-radius: 10px;
  opacity: 0.333;
}

#banner_booking_com:hover,
#banner_booking_join_com:hover,
#banner_airbnb_com:hover,
#banner_skyscanner_ru:hover,
#banner_avia_yandex_ru:hover {
  opacity: 1;
}

#banner_airbnb_com {
  display: none;
}

#html_cache_template_file_name {
  display: none;
}

.tooltip {
  opacity: 1;
}

/*
.fancybox-overlay {
	width: 100%;
}
*/
#main_page_go_to_the_top_row {
  padding: 20px;
}

#main_page_go_to_the_top_row .click {
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
  height: 40px;
  padding: 2px;
  width: 200px;
  background-color: #465b6b;
}

#main_page_go_to_the_top {
  width: 32px;
  height: 32px;
  background: #fff url("/images/top_16x16.png") no-repeat 8px 8px;
  cursor: pointer;
  padding: 5px;
  /*
  border: 1px solid $color_999;
  */
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

#main_page_go_to_the_top_row .text {
  padding-left: 8px;
  /*
  padding-left: 42px;
  padding-right: 8px;
  */
}

#main_page_go_to_the_top_row .text div {
  font-size: 18px;
  color: #fff;
}

.main_title {
  margin: 0 auto;
  padding: 10px;
  justify-content: center;
}

.main_title .main_title_icon,
.main_title .main_title_text {
  float: left;
  padding: 0;
}

.main_title .main_title_text {
  padding-left: 10px;
}

p {
  font-size: 16px;
}

#main_title .txt,
#main_title .img {
  /*

  float: left;

  height: 60px;

  margin-left: 20px;

  */
}

#main_title .img {
  width: 48px;
  /*

  margin-top: 12px;

  */
}

#main_title .arrow_prev,
#main_title .arrow_next,
#main_title .list_all_countries {
  width: 60px;
  /*

  height: 32px;

  height: 64px;

  */
  font-size: 16px;
  font-weight: bold;
  float: right;
  /*

  border: 1px solid #abbfc1;

  */
  text-align: left;
  line-height: 30px;
}

#main_title .arrow_prev {
  margin-right: 10px;
}

#main_title .arrow_prev img,
#main_title .arrow_next img {
  /*

  margin-top: -2px;

  */
}

#main_title .arrow_prev:hover,
#main_title .arrow_next:hover {
  /*

  background-color: #465b6b;

  border: 1px solid #eee;

  color: #fff;

  */
}

#main_title .arrow_prev {
  background: url("/images/arrow_prev_small.png") no-repeat right -1px;
  background: url("/images/arrows-left-icon.png") no-repeat right -1px;
  padding-right: 5px;
}

#main_title .arrow_next {
  background: url("/images/arrow_next_small.png") no-repeat left 0;
  background: url("/images/arrows-right-icon.png") no-repeat left 0;
  padding-left: 5px;
  text-align: right;
}

#main_title .list_all_countries {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

#main_title .list_all_countries .list_countries_block {
  display: none;
  position: absolute;
  margin-left: -218px;
  margin-left: -148px;
  z-index: 88888;
  border: 1px solid #666;
  width: 250px;
  height: 446px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e6f0fb;
  border: 1px solid #abbfc1;
}

#main_title .list_all_countries .list_countries_block .item {
  float: left;
  cursor: pointer;
  text-align: left;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #e6f0fb;
  /*

  height: 22px;

  */
  width: 220px;
}

#main_title .list_all_countries .list_countries_block .item .text {
  font-family: "OpenSansSemibold";
  font-size: 16px;
  margin-top: -5px;
}

#main_title .list_all_countries .list_countries_block .item:hover {
  color: #fff;
  background-color: #465b6b;
}

#main_title .block_all_countries_yes {
  position: relative;
}

#main_title .block_all_countries_yes div {
  float: left;
}

#main_title .block_all_countries_yes .flag {
  width: 32px;
}

#main_title .block_all_countries_yes .prev .link,
#main_title .block_all_countries_yes .next .flag,
#main_title .block_all_countries_yes .prev,
#main_title .block_all_countries_yes .next,
#main_title .block_all_countries_yes .all {
  margin: 0;
  padding: 0;
}

#main_title .block_all_countries_yes .prev {
  margin-right: 10px;
}

#main_title .block_all_countries_yes .next {
  margin-left: 10px;
}

#main_title .block_all_countries {
  margin: 0;
  padding: 0;
}

#main_title .list_all_countries .list_countries_block {
  margin-top: 34px;
}

#main_title .list_all_countries .list_countries_block .item {
  height: 26px;
  padding-top: 2px;
}

.div_0px,
.div_3px,
.div_5px,
.div_10px,
.div_15px,
.div_20px,
.div_30px,
.div_40px,
.div_50px {
  width: 100%;
  clear: both;
}

.div_0px {
  height: 0;
}

.div_3px {
  height: 3px;
}

.div_5px {
  height: 5px;
}

.div_10px {
  height: 10px;
}

.div_15px {
  height: 15px;
}

.div_20px {
  height: 20px;
}

.div_30px {
  height: 30px;
}

.div_40px {
  height: 40px;
}

.div_50px {
  height: 50px;
}

#main_menu_block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #eee;
  z-index: 8000;
}

#main_menu_shadow_down {
  background: url("/images/shadow_down.png") repeat 0 0;
  height: 30px;
  width: 1200px;
  position: fixed;
  top: 60px;
  left: 50%;
  margin-left: -600px;
  z-index: 8000;
}

#main_menu_inner_block {
  position: absolute;
  top: 0;
  width: 1200px;
  height: 60px;
  left: 50%;
  margin-left: -600px;
  background-color: #fff;
  border-left: 1px solid #abbfc1;
  border-right: 1px solid #abbfc1;
  border-bottom: 1px solid #abbfc1;
}

#main_menu_block .main_menu_item,
#main_menu_block .main_menu_item .submenu_block,
#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block,
#main_menu_block .auth .submenu_block .item {
  display: block;
  float: left;
  margin-left: 10px;
  margin-top: 12px;
  cursor: pointer;
  border: 1px solid #abbfc1;
  text-align: center;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #cfd4d5;
  /*
  height: 22px;
  */
}

#main_menu_block .main_menu_item:hover,
#main_menu_block .main_menu_item.active,
#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover {
  border: 1px solid #eee;
  background-color: #465b6b;
}

#main_menu_block .main_menu_item .text,
#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block .item {
  font-family: "OpenSansSemibold";
  font-size: 16px;
  font-weight: normal;
}

#main_menu_block .main_menu_item .text:hover,
#main_menu_inner_block a:hover,
#main_menu_block .main_menu_item.active .text,
#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover {
  color: #fff;
}

#main_menu_mobile_button {
  display: none;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  background: url("/images/mobile_menu2_button.png") no-repeat 0 0;
  cursor: pointer;
}

#main_menu_mobile_button:hover {
  background: url("/images/mobile_menu_button_hover.png") no-repeat 0 0;
}

#main_menu_mobile_block {
  display: none;
  z-index: 9999;
  position: absolute;
  top: 61px;
  left: 0;
  width: 400px;
  background-color: #fff;
}

#main_menu_block .main_menu_mobile_item {
  border: 1px solid #abbfc1;
  background-color: #fff;
}

#main_menu_block .main_menu_mobile_item:hover,
#main_menu_block .main_menu_mobile_item.active {
  /*
  border: 1px solid #eee;
  */
  background-color: #465b6b;
}

#main_menu_block .main_menu_mobile_item .text {
  font-family: "OpenSansSemibold";
  font-size: 24px;
  margin: 10px;
}

#main_menu_block .main_menu_mobile_item .text:hover,
#main_menu_block .main_menu_mobile_item.active .text {
  color: #fff;
}

#main_menu_block .main_menu_item .submenu_block,
#main_menu_block .auth .submenu_block {
  display: none;
  position: absolute;
  top: 33px;
  margin-left: -21px;
  height: auto;
  padding: 0;
}

#main_menu_block .auth .submenu_block .close_button {
  background-image: url("/images/fancybox_sprite.png");
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8989;
}

#main_menu_block .main_menu_item .submenu_block .item,
#main_menu_block .auth .submenu_block .item {
  color: #666;
  border: none;
  float: none;
  margin: 0;
  text-align: left;
}

#main_menu_block .main_menu_item .submenu_block .item:hover,
#main_menu_block .auth .submenu_block .item:hover {
  border: none;
}

/******************************

   LANGUAGE & AUTH

******************************/
#main_menu_lang_block {
  float: right;
  /*
  width: 165px;
  margin-top: 5px;
  position: absolute;
  top: 0px;
  right: 10px;
  */
}

#main_menu_lang_block .lang_ru,
#main_menu_lang_block .lang_en,
#main_menu_lang_block .auth {
  width: 48px;
  height: 48px;
  background-image: url("/images/countries/flags/flag_RU.png");
  cursor: pointer;
  float: right;
  margin-right: 10px;
}

#main_menu_lang_block .lang_en {
  background-image: url("/images/countries/flags/flag_UK.png");
  /*
  margin-right: 50px;
  */
}

#main_menu_lang_block .lang_ru,
#main_menu_lang_block .lang_en {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  /*
  margin-top: 10px;
  */
  border: 1px solid #abbfc1;
}

#main_menu_lang_block .auth,
#main_menu_lang_block .auth span {
  background: none;
  cursor: default;
  font-weight: bold;
  width: 32px;
  font-size: 16px;
  text-align: right;
}

#main_menu_lang_block .auth a,
#main_menu_lang_block .auth span {
  color: #666;
}

#main_menu_lang_block .auth:hover,
#main_menu_lang_block .auth a:hover,
#main_menu_lang_block .auth span:hover {
  color: #fff;
  cursor: pointer;
}

#main_menu_block .auth {
  margin-top: 8px;
}

#main_menu_block .auth .submenu_block {
  top: 38px;
  right: 124px;
}

#main_menu_block .qrcode_builder {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  margin-top: 10px;
  border: 1px solid #abbfc1;
  border: 1px solid #333;
  background-image: url("/images/qrcode_32x32_01.png");
  cursor: pointer;
}

#main_menu_block .qrcode_builder .qrcode_contents {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #333;
  display: none;
  position: absolute;
  margin-top: 33px;
  right: 165px;
}

#main_menu_block .qrcode_builder .close_button {
  background-image: url("/images/fancybox_sprite.png");
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8989;
}

#main_menu_block .auth .menu_avatar,
.avatar_tooltip_html {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  float: left;
  margin: 2px;
}

.avatar_tooltip {
  background-color: #eee;
  border: 2px solid #abbfc1;
  display: none;
}

.avatar_tooltip_html {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar_tooltip_name {
  color: #666;
  font-family: "OpenSansSemibold";
  font-size: 16px;
  /*
  color: #666;
  font-family: 'OpenSansSemibold';
  font-size: 16px;
  padding: 4px;
  border: 1px solid #666;
  background-color: #eee;
  */
}

/***********************************
#	!! $breakpoint_xs:   0;      !! #
#	!! $breakpoint_sm:   576px;  !! #
#	!! $breakpoint_md:   768px;  !! #
#	!! $breakpoint_lg:   992px;  !! #
#	!! $breakpoint_xl:   1200px; !! #
#	!! $brkpnt_min:      321px;  !! #
***********************************/
@media all and (max-width: 1199px) {
  #mozolin_main {
    left: 0;
    margin-left: 0;
    width: 100%;
    /*
    background-color: yellow;
    */
  }
}
@media all and (max-width: 321px) {
  section#mozolin_top .slogan {
    font-size: 11px;
    line-height: 26px;
  }
  #mozolin_main .chat_popup {
    left: 4px;
    margin-left: 0;
  }
  p {
    font-size: 16px;
  }
  section#tillypad_contents .main_text div i {
    font-size: 16px;
  }
}
@media all and (max-width: 576px) {
  section#mozolin_top .slogan {
    font-size: 12px;
    line-height: 26px;
  }
}
@media all and (max-width: 767px) {
  .h5, h5 {
    font-size: 20px;
  }
  .h6, h6 {
    font-size: 16px;
  }
  nav .menu {
    background-color: #fff;
  }
  nav.navbar .dropdown > a {
    color: #465b6b;
    font-weight: bold;
  }
  nav.navbar .ul {
    /* shadow */
    /*
    margin-top: 56px;
    -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
      filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
      filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
      */
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 22px;
  }
  nav.navbar .ul .li .text {
    font-size: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media all and (max-width: 468px) {
  #banner_booking_com,
  #banner_booking_join_com,
  #banner_airbnb_com,
  #banner_skyscanner_ru,
  #banner_avia_yandex_ru {
    width: 100%;
    height: auto;
    padding-left: 3px;
    padding-right: 3px;
  }
}
/***********************************



   1) NEXT-PREV-ALL PHOTOS

   2) NEXT-PREV-ALL CITIES



***********************************/
#gallery_item_page_prev,
#gallery_item_page_next,
#gallery_item_page_all,
#cities_item_page_prev,
#cities_item_page_next,
#cities_item_page_all {
  position: relative;
  /*

  top: -55px;

  left: 5px;

  */
  margin-top: 0px;
  width: 32px;
  height: 32px;
}

#gallery_item_page_all {
  background: url("/images/finger_gallery_32x32_03.png") no-repeat 0 0;
}

#cities_item_page_all {
  background: url("/images/finger_cities_32x32.png") no-repeat 0 0;
  margin-left: 10px;
  margin-right: 10px;
}

#gallery_item_page_prev,
#cities_item_page_prev {
  background: url("/images/arrows-left-icon.png") no-repeat 0 0;
}

#gallery_item_page_next,
#cities_item_page_next {
  background: url("/images/arrows-right-icon.png") no-repeat 0 0;
}

#css_preloader {
  position: relative;
  width: 234px;
  height: 50px;
  margin: auto;
  display: none;
}

.fountainG {
  position: absolute;
  top: 0;
  background-color: #008b95;
  width: 28px;
  height: 28px;
  animation-name: bounce_fountainG;
  -o-animation-name: bounce_fountainG;
  -ms-animation-name: bounce_fountainG;
  -webkit-animation-name: bounce_fountainG;
  -moz-animation-name: bounce_fountainG;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(0.3);
  -o-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  border-radius: 19px;
  -o-border-radius: 19px;
  -ms-border-radius: 19px;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
}

#css_preloader_1 {
  left: 0;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}

#css_preloader_2 {
  left: 29px;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}

#css_preloader_3 {
  left: 58px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}

#css_preloader_4 {
  left: 88px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

#css_preloader_5 {
  left: 117px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}

#css_preloader_6 {
  left: 146px;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

#css_preloader_7 {
  left: 175px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

#css_preloader_8 {
  left: 205px;
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s;
}

@keyframes bounce_fountainG {
  0% {
    transform: scale(1);
    background-color: #008b95;
  }
  100% {
    transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}
@-o-keyframes bounce_fountainG {
  0% {
    -o-transform: scale(1);
    background-color: #008b95;
  }
  100% {
    -o-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}
@-ms-keyframes bounce_fountainG {
  0% {
    -ms-transform: scale(1);
    background-color: #008b95;
  }
  100% {
    -ms-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}
@-webkit-keyframes bounce_fountainG {
  0% {
    -webkit-transform: scale(1);
    background-color: #008b95;
  }
  100% {
    -webkit-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}
@-moz-keyframes bounce_fountainG {
  0% {
    -moz-transform: scale(1);
    background-color: #008b95;
  }
  100% {
    -moz-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}
.ui-helper-hidden-accessible {
  display: none !important;
}