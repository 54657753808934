
/***************
 *             *
 *   CAPTION   *
 *             *
 ***************/
.pswp__custom-caption {
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  color: #ffe;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  text-align: center;
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}

/***********************
 *                     *
 *   DOWNLOAD BUTTON   *
 *                     *
 ***********************/
button.pswp__button--test-button {
  background: #136912 !important;
  font-size: 20px;
  color: #fff;
}

/**********************************
 *                                *
 *   PRELOAD                      *
 *   debug: permanently display   *
 *                                *
 **********************************/
/*
.pswp-with-perma-preloader .pswp__icn {
  opacity: 0.85 !important;
}
*/

/********************
 *                  *
 *   LIKES BUTTON   *
 *                  *
 ********************/
.pswp__button--likes-button .inner {
	background-color: transparent;
	width: 50px;
	height: 60px;
	padding-top: 10px;
}
.pswp__button--likes-button .inner .like {
	position: absolute;
	background-color: #fff;
	text-align: center;
	width: 100%;
	height: 47px;
	padding: 0;
	border: 1px solid #666;
	border-radius: 10px;
}
.pswp__button--likes-button .inner .like img {
	border: 1px solid #fff;
}
.pswp__button--likes-button .inner .like div {
	padding: 0;
}
.pswp__button--likes-button .inner .like .likes-number {
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
}
