﻿/***********************************

   1) NEXT-PREV-ALL PHOTOS
   2) NEXT-PREV-ALL CITIES

***********************************/
#gallery_item_page_prev,
#gallery_item_page_next,
#gallery_item_page_all,
#cities_item_page_prev,
#cities_item_page_next,
#cities_item_page_all
{
	position: relative;
	/*
	top: -55px;
	left: 5px;
	*/
	margin-top: 0px;
	width: 32px;
	height: 32px;
}
#gallery_item_page_all
{
	background: url('/images/finger_gallery_32x32_03.png') no-repeat 0 0;
	
}
#cities_item_page_all
{
	background: url('/images/finger_cities_32x32.png') no-repeat 0 0;
	margin-left: 10px;
	margin-right: 10px;
}
#gallery_item_page_prev,
#cities_item_page_prev
{
	background: url('/images/arrows-left-icon.png') no-repeat 0 0;
}
#gallery_item_page_next,
#cities_item_page_next
{
	background: url('/images/arrows-right-icon.png') no-repeat 0 0;
}
