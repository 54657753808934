#news_container {
	position: absolute;
	
	
	/*
	position: relative;
	left: 300px;
	background-color: yellow;
	width: 100%;
	
	1126 - 200px - 2*5px (margin)
	float: left;
	width: 916px;
	*/
	
	margin: 0 auto;
	min-height: 450px;
	padding: 10px;
}
#news_container .issue_content_news_item,
#news_container .issue_content_news_item.vis,
#news_container .issue_content_news_item.invis
{
	margin: 5px;
	margin-left: 5px;
	width: 210px;
	height: 156px;
	/*
	background-color:pink;
	height:100px;
	*/
	border: 1px solid #abbfc1;
	min-width: 1px;
	/*
	display: none;
	overflow: hidden;
	*/
	background-color: #eee;
}

#news_container .issue_content_news_item .flag {
	cursor: pointer;
}
#news_container .issue_content_news_item .info,
.info_fancybox
{
	/*
	display: none;

	position: fixed;
	top: 65px;
	width: 1000px;
	left: 50%;
	margin-left: -500px;
	*/

	/*
	height: 240px;
	*/
	
	
	width: 1200px;
	/*
	height:100%;
	*/
	
	background-color: #ffffee;
	z-index: 1;
	border: 1px solid #abbfc1;
	overflow-y: hidden;
	overflow-x: hidden;
	text-align: left;
	padding: 5px;
	min-height: 550px;
}

#news_container .issue_content_news_item .info .info_flag {
	
}
#news_container .issue_content_news_item .info .close {
	width: 26px;
	height: 26px;
	background-image: url('/images/icon_plus.png');
	position: absolute;
	top: 3px;
	right: 4px;
	cursor: pointer;
}

#news_container .issue_content_news_item.invis {
	visibility: hidden;
}
#news_container .issue_content_news_item.vis {
	visibility: visible;
}

#news_container .issue_content_news_item .tag {
	display: none;
}
#news_container .issue_content_news_item .inner {
	margin: 5px;
}

