
.blog-world-main {
	width: 100%;
}

/*
.blog_big_like_icon.flag_likes_blog {
	position: relative;
  width: auto;
	height: 34px;
	cursor: pointer;
	border: 1px solid #ccc;
	padding-left: 36px;
	padding-right: 4px;
	background: #fff url("/images/heart_32x32_03.png") no-repeat 0 0;
	-webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
	color: #000;
	font-size: 20px;
	font-weight: bold;
	float: left;
}
*/
#main_title .txt.articles {
	margin-top: 0px;
}
#main_title .txt.city_blog {
	margin-top: 0px;
}

h2 {
	margin-top: 60px;
	margin-top: 0;
	margin-bottom: 0;
}
.list_of_all_blocks,
.blog_accommodation_city
{
	position: relative;
}
.list_of_all_blocks a,
.blog_accommodation_city a
{
	/*
	text-decoration: underline;
	*/
	color: #0070c0;
}
.list_of_all_blocks a:hover,
.blog_accommodation_city a:hover
{
	color: #000099;
	color: #465b6b;
}
.blog_accommodation_city a.subtitle_green_small {
	/*
	text-decoration: underline;
  */
  color: #0070c0;
}
.blog_accommodation_city a.subtitle_green_small:hover {
	/*
	text-decoration: underline;
  */
  color: $color_darkred;
}
.blog_accommodation_country {
	border: 2px solid #fff;
	margin-bottom: 50px;
	margin-top: 0;
	padding-left: 10px;
	padding-right: 10px;
	background-color: $color_eee;
}
.blog_accommodation_country .icon img {
	width: 32px;
	margin-left: 6px;
}
.blog_accommodation_country .name,
.blog_accommodation_country .icon
{
	position: absolute;
	margin-top: -18px;
	margin-left: 10px;
	background-color: #fff;
	height: 32px;
	width: 40px;
}
.blog_accommodation_country .name {
	margin-top: -18px;
	margin-left: 50px;
	background-color: $color_container;
	background-color: $color_white;
	padding-left: 6px;
	padding-right: 6px;
	width: auto;
}
.blog_accommodation_country .name .inside {
	font-size: 22px;
	line-height: 28px;
	font-weight: bold;
}
.blog_accommodation_city_upper {
	padding: 10px;
	background-color: #eee;
}
.blog_accommodation_city {
	padding: 10px;
	padding-top: 20px;
	background-color: #eee;
	/*
	background-color: #fff;
	*/
}
.blog_accommodation_city .title {
	font-size: 20px;
	font-weight: bold;
}
.blog_accommodation_city p img {
	border: none;
}
.blog_accommodation_city {
	padding-left: 9px;
	padding-right: 9px;
}
.blog_ancor {
	/*
	position: absolute;
	margin-top: -40px;
	*/
	margin-top: 0px;
	position: relative;
	height: 1px;
	width: 1px;
	color: #eee;
	opacity: 0;
	filter:Alpha(Opacity=0);
}

.blog_controls,
.blog_contents
{
	position: relative;
	/*
	padding: 10px;
	*/
}
.blog_accommodation_city_blog .blog_contents
{
	text-align: center;
	margin: 0 auto;
}
.blog_accommodation_city_blog .blog_contents .test {
	width: auto;
}
.clear000,
.clear1000
{
	display: none;
}
.div_reload {
	height: 1px;
	display: none;
}
#blog_sitemap_block {
	padding-left: 9px;
	padding-right: 9px;
}
#blog_sitemap_block .sitemap_caption {
	padding: 5px;
	padding-left: 10px;
	text-align: left;
	background-color: $color_default;
	color: $color_white;
	font-weight: bold;
}
#blog_sitemap_block .sitemap_body {
	padding: 10px;
	border: 1px solid $color_grey;
	background-color: $color_eee;
}
#blog_sitemap_block .sitemap_item1 {
	padding-left: 0;
}
#blog_sitemap_block .sitemap_item2 {
	padding-left: 10px;
}
.blog_accommodation_city p,
.blog_accommodation_city .test_row,
#blog_sitemap_block .sitemap_item2
{
	font-size: 16px;
}
.blog_accommodation_city h2,
.blog_accommodation_city h4
{
	color: $color_333;
	font-size: 30px;
}
.blog_accommodation_city h4 {
	font-size: 24px;
}
.blog_accommodation_city p {
	margin-bottom: 0;
	color: $color_333;
}
#blog_sitemap_block .sitemap_caption,
#blog_sitemap_block .sitemap_item1
{
	font-size: 18px;
}
.link_booking_map {
	cursor: pointer;
	min-height: 60px;
	background: $color_default url("/images/map_48x48.png") no-repeat 6px 6px;

	-webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid $color_white;
  
}
.link_booking_map .text {
	padding-left: 60px;
	padding-right: 8px;
	font-size: 18px;
	color: $color_white;
}
.link_booking_map_html {
	/*
	background-color: $color_white;
	*/
	border: 2px solid $color_white;
	padding: 0;
}
.link_booking_map .text:hover {
	/*
	color: $color_darkred;
	*/
}
.blog_accommodation_city .link_icon_title {
	font-size: 16px;
	padding-top: 5px;
	padding-left: 5px;

	cursor: pointer;
	float: left;
}
.blog_accommodation_city a {
	text-decoration: none;
	color: $color_default;
}
.blog_accommodation_city a:hover {
	color: $color_darkred;
}
.blog_accommodation_city .link_icon_info
{
  cursor: pointer;
  padding: 0;
  margin: 2px;
  
  border: 1px solid $color_999;
  border: none;

  float: left;
  /*
  background-color: $color_white;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  */
}
#blog_sitemap_block hr {
	size: 1px;
	color: #999;
}
.list_of_all_blocks {
	margin: 0;
}
h4 {
	padding: 0;
	margin: 0;
}

.blog_accommodation_city .blog_related_articles .hidden_related_items {
	display: none;
}
.blog_accommodation_city .blog_related_articles .more_related_items {
	display: block;
}
.blog_accommodation_city .blog_related_articles .more_link {
	font-size: 16px;
	color: #0070c0;
	cursor: pointer;
	font-style: italic;
	padding-top: 0px;
	padding-left: 18px;
	font-weight: bold;
}
.blog_accommodation_city .blog_related_articles .more_link:hover {
	color: $color_darkred;
}

#blog_sitemap_block .sitemap_item1 a,
#blog_sitemap_block .sitemap_item2 a,
.blog_accommodation_city .blog_related_articles a
{
	font-size: 16px;
	color: #0070c0;
	/*
	text-decoration: underline;
	*/
}
#blog_sitemap_block a.active_link {
	color: $color_default;
}
#blog_sitemap_block .sitemap_item1 a:hover,
#blog_sitemap_block .sitemap_item2 a:hover,
.blog_accommodation_city .blog_related_articles a:hover
{
	color: $color_darkred;
}
#blog_sitemap_block a.active_link {
	font-size: 16px;
}
#blog_sitemap_block a.active_link:hover
{
	background-color: $color_white;
	color: $color_black;
}

#main_page_go_to_the_statistics_row {
	padding: 0;
}
#main_page_go_to_the_statistics_row .click {
	cursor: pointer;
	
	-webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid $color_white;
  height: auto;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;

  width: auto;
  background-color: $color_default;
}
#main_page_go_to_the_statistics {
	width: 32px;
	height: 32px;
	background: $color_white url('/images/bottom_16x16.png') no-repeat 8px 8px;
	cursor: pointer;

	padding: 5px;
  /*
  border: 1px solid $color_999;
  */
  
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
#main_page_go_to_the_statistics_row .text {
	padding-left: 8px;
	/*
	padding-left: 42px;
	padding-right: 8px;
	*/
}
#main_page_go_to_the_statistics_row .text div {
	font-size: 18px;
	color: $color_white;
}

p {
  font-size: 16px;
}

#blog-article a.router-link.image_only,
#blog-article a.router-link.image_only img
{
	padding: 0 !important;
	margin: 0 !important;
}
#blog-article a.router-link.image_only img.flag_small {
	width: 38px;
	height: 38px;
	padding: 0px;
}