#news_container .issue_content_news_item .flag {
	position: absolute;
	top: 32px;
	left: 50%;
	margin-left: -85px;
	cursor: pointer;
	width: 170px;
	height: 114px;
}
#news_container .issue_content_news_item .flag.full {
	top: 8px;
}
#news_container .issue_content_news_item {
	height: auto;
	/*
	overflow: hidden;
	*/
}
#news_container .issue_content_news_item .exif_info,
#news_container .issue_content_news_item .text_info,
#news_container_gallery .issue_content_news_item .exif_info,
#news_container_gallery .issue_content_news_item .text_info
{
	display: none;
}
#news_container #gallery_item_exif_info_icon,
#news_container #gallery_item_text_info_icon
{
	width: 36px;
	height: 36px;
	z-index: 8040;
	display: none;
	background: url('/images/exif_info.png') no-repeat 0 0;
}
#news_container .issue_content_news_item .no_title {
	/*
	height: 154px;
	height: 140px;
	*/
}
#news_container .issue_content_news_item .img_big {
	display: none;
	margin: 0 auto;
}
#news_container .issue_content_news_item .flag .img_big_div {
	position: absolute;
	top: -32px;
	
	z-index: 1;
	text-align: center;
	
	height: 138px;
	width: 208px;
	overflow: hidden;

	left: 50%;
	margin-left: -104px;
}
#news_container .issue_content_news_item .flag.full .img_big_div {
	top: -8px;
	height: 150px;
}
#news_container .issue_content_news_item .img_small {
	display: block;
	margin: 0 auto;
}
#news_container .issue_content_news_item .img_small.no_title_img {
	margin: 4px auto;
}
#news_container .issue_content_news_item_list {
	text-align:left;
	display: none;
	margin-left: 15px;
	margin-top: 0;
}
#news_container .issue_content_news_item_list div a {
	font-size: 16px;
}
#news_container .issue_content_news_item_title {
	position: absolute;
	width: 210px;
	height: 27px;
	font-size: 17px;
	cursor: pointer;
	margin-left: -5px;
}
#news_container .issue_content_news_item_blank {
	height: 155px;
}
#news_container .issue_content_news_item_blank_short {
	height: 140px;
}
#news_container .issue_content_news_item_subtitle {
	position: absolute;
	cursor: pointer;
	left: 0;
	top: 138px;

	/*
	top: 0;
	*/
	width: 208px;
	height: 27px;
	background: url('/images/semitransparent.png') repeat 0 0;
	display: none;
	z-index: 2;
	font-size: 17px;
	line-height: 23px;
	color: #fff;
}

#news_container .issue_content_news_item,
#news_container .issue_content_news_item.vis,
#news_container .issue_content_news_item.invis
{
	border: 1px solid $color_container;
	background-color: $color_container;

	border: 1px solid #abbfc1;
	background-color: #eee;

	height: 167px;
	height: auto;
}

#news_container .issue_content_news_item .img_big_div .img_hotinfo_div {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8080;
	width: 232px;
	height: 140px;
}

#news_container .issue_content_news_item .img_big_div .img_hotinfo_div .text {
	position: absolute;
	top: 50%;
  left: 50%;

	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-box-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'OpenSansBold';
  background: rgba(255,0,0,0.7);
  border: 1px #fff solid;
  border-radius: 8px;

  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 2px;
  width: 160px;

  background-color: #666;
  color: #fff;
  border: 2px #fff solid;

}

/*
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
	background-image: url("/images/fancybox_sprite.png");
	z-index: 9999;
	position: absolute;
	display: block;
}
*/
#news_container .issue_content_news_items {
	position: relative;
}
#news_container {
	position: relative;
}
