/***********************************
#	!! @breakpoint_xs:   0;      !! #
#	!! @brkpnt_min:      321px;  !! #
#	!! @breakpoint_sm:   576px;  !! #
#	!! @brkpnt_sm_1:     575px;  !! #
#	!! @breakpoint_md:   768px;  !! #
#	!! @brkpnt_md_1:     767px;  !! #
#	!! @breakpoint_lg:   992px;  !! #
#	!! @breakpoint_xl:   1200px; !! #
***********************************/

@media all and (max-width: $brkpnt_md)
{
	#vector_map_place {
  	height: 400px;
	}
}
@media all and (max-width: $brkpnt_sm)
{
	#vector_map_place {
  	height: 400px;
	}
}
@media all and (max-width: $brkpnt_min)
{
	#vector_map_place {
  	height: 400px;
	}
}
