﻿/***********************************
#	!! $breakpoint_xs:   0;      !! #
#	!! $breakpoint_sm:   576px;  !! #
#	!! $breakpoint_md:   768px;  !! #
#	!! $breakpoint_lg:   992px;  !! #
#	!! $breakpoint_xl:   1200px; !! #
#	!! $brkpnt_min:      321px;  !! #
***********************************/

@media all and (max-width: $brkpnt_1199)
{
	#mozolin_main {
		left: 0;
		margin-left: 0;
		width: 100%;
		/*
		background-color: yellow;
		*/
	}
}
@media all and (max-width: $brkpnt_min)
{
	section#mozolin_top .slogan {
		font-size: 11px;
		line-height: 26px;
	}
	#mozolin_main .chat_popup {
		left: 4px;
		margin-left: 0;
	}
	p {
		font-size: 16px;
	}
	section#tillypad_contents .main_text div i {
		font-size: 16px;
	}
}
@media all and (max-width: $brkpnt_sm)
{
	section#mozolin_top .slogan {
		font-size: 12px;
		line-height: 26px;
	}
}
@media all and (max-width: $brkpnt_md_1)
{
	.h5, h5 {
		font-size: 20px;
	}
	.h6, h6 {
		font-size: 16px;
	}
	nav .menu {
		background-color: $color_white;
	}
	nav.navbar .dropdown > a {
		color: $color_default;
		font-weight: bold;
	}
	nav.navbar .ul {
		/* shadow */
		/*
		margin-top: 56px;
		-moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    filter: progid:DXImageTransform.Microsoft.dropshadow(offX=5, offY=5, color=#000000);
    filter: progid:DXImageTransform.Microsoft.shadow(direction=120, color=#000000, strength=10);
    */
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-left: 22px;
	}
	nav.navbar .ul .li .text {
		font-size: 22px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	nav.navbar .ul > .li {
		
	}
}

@media all and (max-width: 468px)
{
	#banner_booking_com,
	#banner_booking_join_com,
	#banner_airbnb_com,
	#banner_skyscanner_ru,
	#banner_avia_yandex_ru
	{
		width: 100%;
		height: auto;
		padding-left: 3px;
		padding-right: 3px;
	}
}
