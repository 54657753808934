#vector_map_place{width:450px;height:300px;background-color:#383f47;}
#vector_map{width:100%;height:100%;overflow:hidden;}
#vector_map_overlay{overflow:hidden;}
#vector_map_overlay svg{position:absolute;}
#vector_map_place .settings{display:none;}
#vector_map_place .backgroundColor{color:#84b4e4;}
#vector_map_place .markerStyle_initial_fill{color:#00ff00;}
#vector_map_place .markerStyle_selected_fill{color:#ca0020;}
#vector_map_place .regionStyle_initial_fill{color:#fff;}
#vector_map_place .regionStyle_selected_fill{color:#b8e186;}
#vector_map_place .regionStyle_selectedHover_fill{color:#009900;}
#vector_map_place .series_regions_scale1{color:#c8eeff;}
#vector_map_place .series_regions_scale2{color:#0071a4;}
#vector_map_place .series_regions_scale_visited{color:#b8e186;}
#vector_map_place .series_regions_scale_myhome{color:#fcbeab;}
#vector_map_place .series_regions_scale_other{color:#fff;}

