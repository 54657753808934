
.ui-tooltip, .arrow:after {
  background: #565656;
  border: 1px solid #fff !important;
}
.ui-tooltip {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Helvetica Neue", Sans-Serif;
  font-weight: normal;
  /*
  text-transform: uppercase;
  */
  color: #fff;
  box-shadow: 0 0 7px #565656;
  text-align: center;
}
.arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px;
}
.arrow.top {
  top: -16px;
  bottom: auto;
}
.arrow.left {
  left: 20%;
}
.arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 6px 5px 9px -9px #565656;
}
.arrow.top:after {
  bottom: -20px;
  top: auto;
}
