.gallery_pics {
	width: 170px;
	border-radius: 10px;
}
.fancybox-caption {
  padding: 5px !important;
  text-align: center;
  margin-bottom: 0;
}
.fancybox-caption::before {
  display: inline-block;
  box-sizing: border-box;
}
.likes-html-template {
	display: none;
	z-index: 999999999999;
	background: red;
}