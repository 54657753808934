#vector_map_place {
	position: relative;
	width: 100%;
  height: 500px;
  background-color: #84b4e4;
  overflow: hidden;
  z-index: 1;
  margin-top: 20px;
}
#vector_map_routes
{
	position: relative;
	padding: 5px;
	text-align: left;
	background-color: #eee;
	border: 1px solid #abbfc1;
	margin-top: 20px;
}
#vector_map_routes a {
	color: #465b6b;
}
#vector_map_routes a:hover {
	color: #990000;
}
#vector_map_routes .link,
#vector_map_routes .distance
{
	font-family:OpenSansBold;
	font-size: 16px;
	cursor: pointer;
	line-height: 20px;
}
#vector_map_routes .distance {
	
}
#vector_map_routes .link:hover {
	color: #990000;
}

#vector_map,
#vector_map_place .uk_countries_mill,
#vector_map_place .ru_mill,
#vector_map_place .ru_fd_mill
{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  opacity: 0.80;
  filter: alpha(Opacity=80);
}
#vector_map_place .close_btn {
	margin-top: 5px;
	margin-left: 5px;
	width: 26px;
	height: 26px;
	background: transparent url('/images/icon_plus.png?0') 0 0;
	z-index: 100;
	cursor: pointer;
}
.svgMapOverlay{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
}
#distance_and_directions {
	position: relative;
	padding: 10px;
	text-align: left;
}
#distance_and_directions .td {
	padding-left: 5px;
	padding-right: 5px;
}
#distance_and_directions .th {
	padding-left: 5px;
	padding-right: 5px;
	background-color: #666;
	color: #eee;
	text-align: left;
}
#distance_and_directions .right {
	text-align: right;
}
#distance_and_directions .bg_light {
	background-color: #eee;
}
#distance_and_directions .bg_dark {
	background-color: #ddd;
}
#distance_and_directions .bg_light.bg_future {
	background-color: #eee9df;
}
#distance_and_directions .bg_dark.bg_future {
	background-color: #e2d9c7;
}
#distance_and_directions .total {
	font-size:16px;
	font-family:OpenSansBold;
	font-weight: bold;
	background-color: #666;
	color: #eee;
}
#distance_and_directions .td_right {
	text-align: right;
	font-weight: bold;
}
#distance_and_directions .td_right.red {
	color: #ffff00;
}
