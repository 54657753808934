#vector_map_place .settings {
	display: none;
}
#vector_map_place .backgroundColor {
	color: #84b4e4;
}
#vector_map_place .markerStyle_initial_fill {
	color: #ccc;
	color: #ffc;
}
#vector_map_place .markerStyle_selected_fill {
	color: #ca0020;
}
#vector_map_place .markerStyle_selectedHover_fill {
	color: #fff;
}
#vector_map_place .regionStyle_initial_fill {
	color: #fff;
}
#vector_map_place .regionStyle_selected_fill {
	color: #00f;
}
#vector_map_place .regionStyle_selectedHover_fill {
	color: #009;
}
#vector_map_place .series_regions_scale1 {
	color: #c8eeff;
}
#vector_map_place .series_regions_scale2 {
	color: #0071a4;
}


.here_home    {
	background-color: #f00;
	color: #fff;
}
.here_visited {
	background-color: #00f;
	color: #fff;
}
.here_others  {
	background-color: #fff;
	color: #1a242e;
	color: #000;
}
.here_plans   {
	background-color: #ffc657;
	color: #1a242e;
}
.here_table {
	/*
	border: 1px solid #bbb;
	*/
	width: 100%;
}
.here_col1 {
	text-align: left;
	padding-left: 10px;
}
.here_col2 {
	text-align: right;
	padding-right: 10px;
}


#vector_map_place .series_regions_scale_visited {
	color: #00f;
}
#vector_map_place .series_regions_scale_plans_planning {
	color: #ff0;
	color: #ffc657;
}
#vector_map_place .series_regions_scale_myhome {
	color: #f00;
}
#vector_map_place .series_regions_scale_myhomeHover {
	color: #900;
}
#vector_map_place .series_regions_scale_other {
	color: #fff;
}
