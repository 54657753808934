﻿.accommodation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.accommodation-modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}
.accommodation-modal-content > * + *{
  margin: 10px 0;
}
.accommodation-modal-content h1 {
  font-size: 16px;
}
.accommodation-modal-content button {
  margin: 0 auto;
  padding: 5px 15px;
  border: 1px solid;
  border-radius: 5px;
  color: $color_white;
  background-color: $color_default;
}
.dark .accommodation-modal-content {
  background: #000;
}
